import axios from "axios";
/**
 * Computed the api resource endpoint
 * @param {String} accountId Id of the account whose details are to be fetched
 * @param {String} endpoint Api resource from where the data is to be fetched
 * @returns {String} Computed api resource endpoint to fetch the data
 */
export const getEndpoint = (accountId, endpoint) => `${accountId}/${endpoint}`;

/**
 * isCancelRequest
 * @description Is current http request cancelled
 * @returns {Boolean}
 */
export const isCancelRequest = (error) => axios.isCancel(error);

/**
 * getCancelToken
 * @description Creates cancel token object
 * @returns {CancelToken}
 */
export const getCancelToken = () => axios.CancelToken;
