/**
 * Vuex persist configuration options
 */
const vuexConfig = {
  // Vuex persist options
  vuexPersist: {
    // Key of the store
    key: "inbox-aware",

    // Storage to save the store
    storage: window.localStorage,

    /**
     *  Add all the modules in the array to persist
     * @description Add name of the modules in the array
     * */
    modules: ["app", "ui", "auth", "settings"],
  },
};

export default Object.freeze(vuexConfig);
