import dayjs from "dayjs";
import store from "@/store";
import { sum } from "@/injectables";

import appConfig from "@/config/app.config";
import { MAILBOX_PROVIDER_METRICS } from "@/constants";
import { IGNORE_CSV_LABEL, DISPLAY_BREAKPOINTS } from "@/constants/app";

const { defaultLocale } = appConfig;

/**
 * Calculates the date from which the accounts record are to be fetched
 * @param {Number} days User selected days
 * @returns {Object} from_date is current date minus days param and to_date is current date
 */
export const getSelectedFilterDayDate = (days) => {
  let [date, to_date, from_date] = [dayjs.utc(), "", ""];
  // Fetch user preffered timezone
  const userTimezone = store.getters["auth/currentUserProfile"]?.time_zone;

  // If userTimezone exists then change the time to user specified timezone
  if (userTimezone)
    date = new Date(
      new Date().toLocaleString(defaultLocale, { timeZone: userTimezone })
    );

  from_date = dayjs(date)
    .subtract(days, "day")
    .format("YYYY-MM-DD");

  to_date = dayjs(date).format("YYYY-MM-DD");

  return {
    from_date,
    to_date,
  };
};

/**
 * Creates a file and then prompts a window for the user to download that file
 * @param {String} filename Name of the file to be downloaded
 * @param {String} text - Text of the file
 * @param {String} fileType Type of file to be created
 * @type {File}
 */
export const downloadFile = (
  filename = "file",
  text,
  fileType = "text/plain"
) => {
  // Set up the link
  var link = document.createElement("a");
  link.setAttribute("target", "_blank");

  // If Blob is defined in the window
  if (Blob !== undefined) {
    var blob = new Blob([text], { type: fileType });
    link.setAttribute("href", URL.createObjectURL(blob));
  } else {
    // If blob is not defined or not supported by the browser
    link.setAttribute("href", "data:text/plain," + encodeURIComponent(text));
  }
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  // Click the link and downloads the file
  link.click();
  document.body.removeChild(link);
};

/**
 * Checks if the object is empty or not
 * @param {Object,Array} object Object to check if it is empty or not
 * @returns {Boolean} Boolean value determining if object is empty or not
 */
export const isEmpty = (object) => {
  if (Array.isArray(object)) {
    return object && object.length === 0;
  } else if (isType(object, "object") && object instanceof Object) {
    return Object.keys(object).length === 0 && object.constructor === Object;
  } else {
    return true;
  }
};

/**
 * Iterates over the array of object and retuns a new array which contains all prop value in the array objects
 * @param {Array} arr Array of object to be iterated
 * @param {String} prop Property name whose value are to be fetched from the array
 * @returns {Array} Array of all the prop values in it
 */

export const getPropArr = (arr, prop) => {
  if (!Array.isArray(arr) || !prop) return [];

  const propsArr = arr.map((data) => data[prop]);
  return propsArr;
};

/**
 * Checks if the object is of a particular data type
 * @param {*} object Object to check it's data type
 * @param {*} type Data type of the object to be expected
 * @returns Boolean Whether it of specified data type
 */
export const isType = (object, type) => typeof object === type;

/**
 * Finds the diff between 2 arrays
 * @param {Array} arr1 First array to be differetiated
 * @param {Array} arr2 Second array with whom to be differentiated
 * @returns {Array} New array which contains diff elements between 2 arrays
 */
export const diffArr = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    throw new Error();
  }

  return arr1.filter((data) => !arr2.includes(data));
};

/**
 * Generates uuid (Universal Unique Identifier)
 * Link for reffernce [https://gist.github.com/jed/982883]
 * @returns {String} UUID
 */
export const uuidv4 = () => {
  const regx = [1e7] + -1e3 + -4e3 + -8e3 + -1e11;
  return regx.replace(/[018]/g, generateUuid);
};

/**
 * Generates UUID
 */
const generateUuid = (c) =>
  (
    c ^
    (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
  ).toString(16);

/**
 * Checks the api response code with the status param of the api response
 * @todo Add new api response code cases in the function
 * @param {Number} status Api status code to check
 * @param {Number} status Api status name to be validated
 * @returns {Boolean} Is api status same as
 */
export const isResponseCode = (code, status) => code === status;

/**
 * Check object have atleast one defined key value pair
 * @param {Object} object
 * @returns Boolean
 */
export const hasAnyPropsValue = (object) => {
  return object
    ? Object.values(object).some((prop) => (prop ? !isEmpty(prop) : prop))
    : false;
};

/**
 * defer
 * @description simply delays an action from happening
 * @param {Function} callback - the callback to execute
 * @param {String} delay - the number in milliseconds to delay callback
 */
export const defer = (callback, delay = 300) => setTimeout(callback, delay);

/**
 * Avoid user to enter "e" or "E" char in number fields
 * @param {Object} event
 */
export const onlyNumber = (event) => {
  let keyCode = event.keyCode ? event.keyCode : event.which;
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
    event.preventDefault();
  }
};

/**
 * getMailBoxProviderSeedCount
 * @param {Array} data Mailbox provider
 * @returns {Number} Total seeds presen in mailbox provider ot camapigns
 */
export const getMailBoxProviderSeedCount = (data) => {
  let totalSeedCount = Object.keys(MAILBOX_PROVIDER_METRICS).map((metric) => {
    const metricsCount = getPropArr(data, metric);
    const metricsSum = sum(metricsCount);
    return metricsSum;
  });
  return sum(totalSeedCount);
};

/**
 * getSeedCount
 * @param {Object} record Seed records
 * @returns {Number} Total Seeds counts
 */
export const getSeedCount = (record) => {
  let seedsCount = Object.keys(MAILBOX_PROVIDER_METRICS).map(
    (metric) => record[metric]
  );
  seedsCount = seedsCount ? sum(seedsCount) : 0;
  return seedsCount;
};

/**
 * Updates the store value
 * @param {Object} oldState Old state of the store
 * @param {Object} updatedState Updated state of the store
 * @returns {Object} Updated state
 */
export const updateStore = (oldState, updatedState) => ({
  ...oldState,
  ...updatedState,
});

/**
 * Computes the csv file labels
 * @param {Array} headers Array of csv file labels
 * @returns {Object} Object containing labels of csv file to be downloaded
 */
export const csvFileLabels = (headers) => {
  const ERROR = "csvFileLabels method headers argument should be an array.";
  if (!Array.isArray(headers)) throw new Error(ERROR);

  let labels = {};
  // Checks if label is ignored or not
  const isLabelIgnored = (value) => value === IGNORE_CSV_LABEL;

  headers?.forEach(
    ({ text, value }) => !isLabelIgnored(value) && (labels[value] = text)
  );
  return labels;
};

/**
 * Creates a new object containing all key value pair properties present in the props array
 * @param {Object} object Object from which the properties are to be fetched
 * @param {Array} props Array of string (Object properties) name which are to be fetched from object argument and then added into new object
 * @returns {Object} New object containing properties
 */
export const pick = (object, props) => {
  let newObject = {};

  // Iterates over props array and add key value pair in newObject
  props.forEach((prop) => {
    // Checks whether property exixts in exixting object
    const hasProp = Object.hasOwnProperty.call(object, prop);
    if (hasProp) newObject[prop] = object[prop];
  });

  return newObject;
};

export const uniqByProp = (data, uniqProp, isObject = false) => {
  const uniqObj = data.reduce(
    (map, obj) => map.set(obj[uniqProp], obj),
    new Map()
  );

  return isObject ? [...uniqObj.values()] : [...uniqObj.keys()];
};
/**
 * Function to check if an object contains an property
 * @param {Object} object Object in which to find a property is defined or not
 * @param {String} prop Name of the property to check its presence in the Object recieved in the param
 * @returns {Boolean} Is property present in the object or not
 */
export const hasProp = (object = {}, prop) => {
  if (!object || !prop)
    throw new Error(
      "hasProp function has object and prop as required arguments."
    );
  return Object.prototype.hasOwnProperty.call(object, prop);
};

/**
 * Determines that current view breakpoint is mobile or not
 * @returns {Boolean}
 */
export const isMobile = ({ breakpoint }) => {
  const { sm, xs } = DISPLAY_BREAKPOINTS;
  return [sm, xs].includes(breakpoint.name);
};

/**
 * toTimezone
 * @description Converts date into user specific timezone
 * @returns {dayjs} Date object
 */
export const toTimezone = (date, timezone) => {
  if (!date || !timezone) {
    throw new Error("toTimezone function arguments are missing.");
  }

  return dayjs(date).tz(timezone);
};
