<template>
  <v-navigation-drawer
    app
    ref="navigation"
    clipped
    v-model="drawer"
    hide-overlay
    :permanent="$vuetify.breakpoint.mdAndUp"
    :mini-variant.sync="showMini"
    :mini-variant-width="60"
  >
    <v-list dense :class="{ 'nav-drawer-list': isMobile }">
      <template
        v-for="({ icon, text, to, query, elementId, ...rest }, i) in navItems"
      >
        <v-list-item
          link
          exact-path
          :key="`${i}_${text}`"
          :to="{ path: to, query }"
          @click="selectedLink = to"
          class="nav--link subtitle-2"
          active-class="nav--link-active"
          :id="elementId"
          :class="[rest.class, drawer ? 'pr-4' : 'pr-1']"
        >
          <tooltip
            #default="{ on, attrs }"
            :name="text"
            :disabled="!isDrawerOpen"
            position="right"
          >
            <v-list-item-icon
              v-bind="attrs"
              v-on="on"
              :class="[
                { 'mr-4': !drawer },
                { 'nav-icon-responsive': isMobile },
              ]"
            >
              <v-icon v-text="icon" class="text-dark-grey" />
            </v-list-item-icon>
          </tooltip>
          <v-list-item-content>
            <v-list-item-title
              :class="{ 'pr-1': !drawer && selectedLink !== to }"
              class="subtitle-2 text-uppercase font-weight-bold secondary--font overflow-visible"
            >
              {{ text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { Tooltip } from "../components/shared";

/**
 * Left side bar nav drawer
 */
export default {
  name: "NavDrawer",
  /**
   * -------------- Props -------------
   */
  props: {
    isDrawerOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  /**
   * -------------- Components -------------
   */
  components: {
    Tooltip,
  },
  /**
   * Custom events emitted by the component
   */
  emits: ["toggle-drawer"],
  /**
   * -------------- Data Properties -------------
   */
  data() {
    return {
      selectedLink: "",
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      selectedAccount: "ui/selectedAccount",
    }),
    /**
     * Determines if current breakpoint of mobile
     * @type {Boolean}
     */
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    /**
     * Shows left side drawer icons when the drawer is closed
     * @description it should be opposite of drawer prop
     */
    showMini: {
      get() {
        return this.$vuetify.breakpoint.smAndDown ? false : this.drawer;
      },
      set() {
        // Commented to not open the drawer on nav link click
        // this.toggleDrawer();
      },
    },
    /**
     * Computed property to toggle the drawer
     */
    drawer: {
      get() {
        return this.isDrawerOpen;
      },
      /**
       * Toggles nav drawer
       */
      set() {
        // call toggleDrawer only when menu inactive
        !this.$refs.navigation.isBooted && this.toggleDrawer();
        !this.$refs.navigation.isActive && this.toggleDrawer();
      },
    },
    /**
     * Left side drawer nav items
     */
    navItems() {
      return [
        {
          icon: "mdi-home",
          elementId: "dashboard",
          text: "Dashboard",
          to: this.$paths.dashboard,
        },
        {
          icon: "mdi-inbox-arrow-down",
          elementId: "inbox_monitoring",
          text: "Inbox Monitoring",
          to: this.selectedAccount.path,
        },
        {
          icon: "unsubscribe",
          elementId: "blocklist_monitoring",
          text: "Blocklist Monitoring",
          to: this.$paths.blocklistMonitoring,
          class: "material-icons d-flex",
        },
        {
          icon: "mdi-cog",
          elementId: "account_settings",
          text: "Account Settings",
          to: this.$paths.accountSettings,
          query: { active_tab: "account_information" },
        },
      ];
    },
  },
  /**
   * -------------- Methods -------------
   */
  methods: {
    /**
     * Emits an event to toggle the left side nav drawer
     */
    toggleDrawer() {
      this.$emit("toggle-drawer");
    },
  },
  /**
   * -------------- Mounted lifecycle hook -------------
   */
  mounted() {
    this.selectedLink = this.navItems[0].to;
  },
};
</script>
<style lang="scss" scoped>
// Left side bar nav links
.nav--link.v-list-item:focus {
  &::before {
    color: var(--v-primary-base) !important;
  }
}

.v-list {
  .nav--link {
    min-height: 52px !important;

    .v-list-item__content {
      color: $dark--grey;
    }
    &.nav--link-active {
      .v-list-item__content,
      .v-icon {
        color: var(--v-primary-base) !important;
      }
    }
    
    .v-list-item__icon {
      margin-top: 14px;
    }

    .nav-icon-responsive {
      &.v-list-item__icon {
        margin-right: 14px !important;
      }
    }

    &.theme--light.v-list-item:hover::before {
      background-color: var(--v-primary-base);
    }

    &.v-list-item--active {
      color: var(--v-primary-base) !important;
      border-left: 4px solid var(--v-primary-base);
      padding-right: 22px;
      padding-left: 12px;
      .v-icon {
        color: var(--v-primary-base) !important;
      }
    }
  }
}

.nav-drawer-list {
  padding-top: calc(50% - 64px);
}
</style>
