<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        large
        text
        outlined
        class="text-capitalize text-spaced--0 days-filter__btn btn-light-grey-border pl-4 pr-3"
      >
        <v-icon class="mr-1 text--secondary">{{ prependIcon }}</v-icon>
        <span
          class="mx-3 subtitle-1 text--primary primary--font font-weight-medium pr-6"
        >
          {{ selectedText }}
        </span>
        <v-icon class="mr-1 text--secondary">mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list class="w-100 days-filter__btn__dropdown" dense>
      <v-list-item-group v-model="value" color>
        <v-list-item
          link
          class="py-0 px-5"
          v-for="(name, i) in items"
          :value="name"
          :key="i"
        >
          <v-list-item-content>
            <v-list-item-title class="text-capitalize subtitle-1">
              {{ appendText }} {{ name }} {{ prependText }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "DaysFilter",
  /**
   * ---------------- Props ------------------
   */
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedDay: {
      type: Number,
      required: true,
    },
    prependText: {
      type: String,
      default: "days",
    },
    appendText: {
      type: String,
      default: "Last",
    },
    prependIcon: {
      type: String,
      default: "mdi-calendar-range",
    },
  },
  /**
   * ---------------- Custom events emitted ------------------
   */
  emits: ["update-day"],
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    selectedText() {
      return `${this.appendText} ${this.value} ${this.prependText}`;
    },
    value: {
      get() {
        return this.selectedDay;
      },
      set(value) {
        if (!value) return;
        this.$emit("update-day", value);
      },
    },
  },
};
</script>
 