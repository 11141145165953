import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

/**
 * Uses utc plugin
 * @description Extend the plugins which are to be used over here
 */
dayjs.extend(utc);
dayjs.extend(timezone);
