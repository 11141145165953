import { MONTHS } from "@/constants/app";

/**
 * Fetches the month name
 * @returns {String} month name
 */
const getMonthName = function() {
  const monthNo = this.getMonth();
  return MONTHS.month_names_short[monthNo];
};

/**
 * Calculates the time of the date in am and pm format
 * @returns {String} String time format in am and om
 */
const formatAmPm = function(useUTC = true) {
  let hours = useUTC ? this.getUTCHours() : this.getHours();
  let minutes = useUTC ? this.getUTCMinutes() : this.getMinutes();

  // Checks if hours are greater than or equal to 12 then it is pm
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Prefixes 0 with minutes if minutes are less than 10
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  let strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};

/**
 * Object contaiing all the methods to be injected in the Date object
 */
const dateUtils = {
  formatAmPm,
  getMonthName,
};

/**
 * Iterates over dateUtils object and injects all the methods in to Date object globally
 */
export const injectDateUtils = () => {
  for (const key in dateUtils) {
    Date.prototype[key] = dateUtils[key];
  }
};

injectDateUtils();
