import { auth } from "./auth";
import { $http } from "./apis/http/http";
import { $accounts } from "@/services/apis/accounts";

import apiEndpoints from "@/constants/api-endpoints";
import { getEndpoint } from "@/utils/api.utils";

/**
 * Get list of all users
 * @param {Object} params
 * @returns {Promise}
 */
export const getUsers = ({ account_id, ...params }) => {
  const endpoint = getEndpoint(account_id, apiEndpoints.users);
  return $accounts.get(endpoint, { params });
};

/**
 * Update user details
 * @param {Object} params User request params
 * @returns {Promise}
 */
export const updateUser = ({ account_id, id, ...params }) => {
  const endpoint = `${getEndpoint(account_id, apiEndpoints.users)}/${id}`;
  return $accounts.patch(endpoint, params);
};

/**
 * Updates the phone number of the current logged in user
 * @param {String | Number} phoneNumber Phone number of the user to be updated
 * @returns {Promise}
 */
export const updatePhoneNumber = (phoneNumber) => {
  return auth.currentUser.updatePhoneNumber(phoneNumber);
};

/**
 * Creates a new login account of the user on the basis of the email and password input so that he can login into the application
 * @param {String} email Email address of the user account to be created
 * @param {String} password Password of the user account
 * @returns {Promise}
 */
export const createUser = (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password);
};

/**
 * Create user Profile by hitting server api
 * @param {Object} param - pass attribute to api
 * @returns {Promise}
 */
export const createUserProfile = ({ account_id, ...params }) => {
  const endpoint = getEndpoint(account_id, apiEndpoints.users);
  return $accounts.post(endpoint, params);
};

/**
 * Add User to an existing account
 * @param {Object} param0 - Pass attribute role, user, account_id
 * @returns {Promise}
 */
export const addUserToAccount = (payload) => {
  return $http.post(apiEndpoints.addUserToAccount, payload);
};

/**
 * get Listing of Roles
 * @param {Object} params
 * @returns {Promise}
 */
export const getRoles = (params) => {
  return $http.get(apiEndpoints.roles, { params });
};

/**
 * Removed user from account
 * @param {Object} param
 * @returns {Promise}
 */
export const removeUserFromAccount = ({ account_id, id }) => {
  const endpoint = `${getEndpoint(account_id, apiEndpoints.users)}/${id}`;
  return $accounts.delete(endpoint);
};

/**
 * Fetches a user detail
 * @returns {Promise} Detail of the user
 */
export const getUser = ({ account_id, id }) => {
  const endpoint = `${getEndpoint(account_id, apiEndpoints.users)}/${id}`;
  return $accounts.get(endpoint);
};

/**
 * Update user role
 * @returns {Promise} Updated user details
 */
export const updateUserDetails = (accountId, id, payload) => {
  const endpoint = `${getEndpoint(accountId, apiEndpoints.users)}/${id}`;
  return $accounts.put(endpoint, payload);
};
