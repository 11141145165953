<template>
  <span>
    <template v-if="showThresholdChip">
      <v-chip
        label
        class="thresholds-chip px-1"
        :text-color="$appConfig.thresholds.textColor"
        :color="$appConfig.thresholds.backgroundColor"
      >
        {{ percent }}%
      </v-chip>
    </template>
    <span v-else-if="hasPercent" class="body-2 text--primary text-black--80">
      {{ percent }}%
    </span>
    <span v-if="hasThresholds" class="body-2 text--secondary">
      ({{ count }})
    </span>
    <span v-if="text" class="body-2 text--primary text-black--80">
      {{ text }}
    </span>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "@/utils/common.utils.js";

/**
 * Renders the table row matric (spam, inbox, missing) details
 */
export default {
  name: "TableMatricsRow",
  /**
   * ---------------- Props ------------------
   */
  props: {
    count: {
      type: [Number, String],
      default: "",
    },
    percent: {
      type: [Number, String],
    },
    /**
     * Type of threshold percentage being rendered on the ui
     * @values inbox, spam, missing
     * @description Thresholds values mentioned above can be accessed by $appConfig.thresholds[threshold_value] in template
     */
    thresholdType: {
      type: String,
      default: "",
    },
    /**
     * Text to be displayed
     */
    text: {
      type: [String, Number],
      default: "",
    },
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      selectedAccountThresholds: "ui/selectedAccountThresholds",
    }),
    /**
     * Determines that selected account has threshold settings to display the avatar in the UI metric listing
     * @type {Boolean} Has selected account thresholds or not
     */
    hasThresholds() {
      const showThresholds =
        this.selectedAccountThresholds && this.thresholdType;

      return showThresholds ? !isEmpty(this.selectedAccountThresholds) : false;
    },
    /**
     * Threshold percentage
     * @type {Number}
     */
    thresholdPercent() {
      if (!this.thresholdType || !this.hasThresholds) return "";

      const { inbox, spam, missing } = this.$appConfig.thresholds;

      switch (this.thresholdType) {
        case inbox:
          return this.selectedAccountThresholds?.inbox_threshold;
        case spam:
          return this.selectedAccountThresholds?.spam_threshold;
        case missing:
          return this.selectedAccountThresholds?.missing_threshold;

        default:
          return 0;
      }
    },

    /**
     * Threshold isPercentGreater
     */
    isPercentGreater() {
      const { inbox, spam, missing } = this.$appConfig.thresholds;

      switch (this.thresholdType) {
        case inbox:
          return this.percent < this.thresholdPercent;
        case spam:
          return this.percent > this.thresholdPercent;
        case missing:
          return this.percent > this.thresholdPercent;

        default:
          return 0;
      }
    },

    /**
     * Whether to display the threshold metric in the chip or not
     * @type {Boolean}
     */
    showThresholdChip() {
      return this.hasThresholds && this.percent ? this.isPercentGreater : false;
    },
    /**
     * Checks if the count props have a valid count
     * @type {Boolean}
     */
    hasPercent() {
      return this.percent !== null && typeof this.percent !== "undefined";
    },
  },
};
</script>
