import { $accounts } from "./apis/accounts";
import apiEndpoints from "@/constants/api-endpoints";
import { getEndpoint } from "@/utils/api.utils";
import { $http } from "./apis/http/http";

// Define all the accounts api services in this file

/**
 * Fetches accounts from the api resource
 * @param {Object} params - Api params
 * @returns {Promise} APi response
 */
export const getAccounts = (params) => {
  return $accounts.get("", { params });
};

/**
 * Fetches account campaigns from the api resource
 * @param {Object} params - Api params
 * @returns {Promise} APi response with account campaigns list
 */
export const getAccountCampaigns = ({
  account_id,
  cancelToken = "",
  ...params
}) => {
  const endpoint = getEndpoint(account_id, apiEndpoints.accountCampaigns);
  return $accounts.get(endpoint, { params, cancelToken });
};

/**
 * Fetches a account campaign details
 * @param {Object} params - Api params
 * @returns {Promise} APi response with account campaign details
 */
export const getAccountCampaign = ({ account_id, id, ...params }) => {
  const endpoint = getEndpoint(account_id, apiEndpoints.accountCampaigns);
  return $accounts.get(`${endpoint}/${id}`, { params });
};

/**
 * Fetches campaign mailbox providers from the api resource
 * @param {Object} params - Api params
 * @returns {Promise} APi response
 */
export const getCampaignMailboxProviders = ({
  account_id,
  campaign_id,
  ...params
}) => {
  let endpoint = apiEndpoints.campaignMailboxProviders.replace(
    "{{campaign_id}}",
    campaign_id
  );
  endpoint = getEndpoint(account_id, endpoint);
  return $accounts.get(endpoint, { params });
};

/**
 * Fetch mailbxo providers to be shown in the mailbox provider filters
 * @returns {Promise} Promise containing mailbox providers to be shown in the filters
 */
export const getMailboxProviders = (params = null) => {
  params && delete params.account_id;
  return $http.get(apiEndpoints.mailboxProviders, { params });
};

/**
 * Fetches email address seeds from the api resource
 * @param {Object} params - Api params
 * @returns {Promise} APi response
 */
export const getSeeds = ({ account_id, ...params }) => {
  const endpoint = getEndpoint(account_id, apiEndpoints.seeds);
  return $accounts.get(endpoint, { params });
};

/*
 * Fetches sending domains from the api resource
 * @param {Object} params - Api params
 * @param {String} account_ids Single account id whose from domain are to be fetched
 * @returns {Promise} API response
 */
export const getSendingDomains = ({
  account_ids,
  cancelToken = "",
  ...params
}) => {
  const endpoint = getEndpoint(account_ids, apiEndpoints.sendingDomains);
  return $accounts.get(endpoint, { params, cancelToken });
};

/**
 * Fetches sending ips from the api resource
 * @param {Object} params - Api params
 * @returns {Promise} API response
 */
export const getSendingIPs = ({ account_id, cancelToken = "", ...params }) => {
  const endpoint = getEndpoint(account_id, apiEndpoints.sendingIPs);
  return $accounts.get(endpoint, { params, cancelToken });
};

/**
 * Sends a post request to create a new account
 * @param {Object} params - Api params new account detaila
 * @returns {Promise} API response
 */
export const createAccount = ({ id, ...params }) => {
  const endpoint = getEndpoint(id, "");
  return $accounts.put(endpoint, params);
};

/**
 * Fetches account details from the api resource
 * @param {Object} params - Api params
 * @returns {Promise} Api response account details
 */
export const getAccount = ({ account_id, ...params }) => {
  const endpoint = `/${account_id}`;
  return $accounts.get(endpoint, { params });
};

/**
 * Fetches list of account mailbox providers
 * @param {String} id Id of the account whose mailbox providers are to be fetched
 * @returns {Promise} Promise containing list of mailbox providers
 */
export const getAccountMailboxProviders = (id) => {
  return $accounts.get(getEndpoint(id, apiEndpoints.mailboxProviders));
};

/**
 * Adds or updates mailbox providers of an account
 * @param {String} id Id of the account where mailbox providers are to be added
 * @returns {Promise} Promise whether mailbox providers were added or not
 */
export const addAccountMailboxProviders = (id, mailboxProviderId, payload) => {
  return $accounts.put(
    `${getEndpoint(id, apiEndpoints.mailboxProviders)}/${mailboxProviderId}`,
    payload
  );
};
