import Vue from "vue";
import VueRouter from "vue-router";
import routeMiddlewares from "./middlewares";

import routes from "./routes";

/**
 * Doesnot intialise router when the env is test
 */
if (process.env.NODE_ENV !== "test") {
  // Initalises the vue router
  Vue.use(VueRouter);
}

const router = new VueRouter({
  routes,
});

/**
 * Router middleware which runs whenever user tries to change the route
 * @description Authorises routes on the bassis of the login status
 */
router.beforeEach(routeMiddlewares.auth);

export default router;
