<template>
  <div class="auth-wrapper">
    <v-card
      exact
      :height="cardDimensions.height"
      :width="cardDimensions.width"
      class="pa-8"
    >
      <v-card-title
        primary-title
        :class="titleStyle"
        class="d-flex justify-center pa-0"
      >
        <div class="inboxaware-logo">
          <v-img
            contain
            height="24px"
            width="252px"
            alt="Inboxaware logo"
            :src="$images.formLogo"
          />
        </div>
      </v-card-title>
      <v-card-text class="pa-0">
        <!-- Default slot -->
        <slot></slot>
      </v-card-text>
    </v-card>
    <div
      class="text-center mt-4 subtitle-1"
      id="terms-conditions"
      v-if="showTermsAndConditions"
    >
      <a
        target="blank"
        :href="$appConfig.inboxAwareSite.termsConditions"
        class="text-decoration-none font-weight-regular black--text"
      >
        {{ $dictionary.app.authFormWrapper.terms }}
      </a>
      |
      <a
        target="blank"
        :href="$appConfig.inboxAwareSite.privacyPolicy"
        class="text-decoration-none font-weight-regular black--text"
      >
        {{ $dictionary.app.authFormWrapper.privacy }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DISPLAY_BREAKPOINTS } from "../../constants";

const [HEIGHT, WIDTH] = ["392px", "448px"];
/**
 * Auth form wrapper
 */
export default {
  name: "AuthFormsWrapper",
  /**
   * ---------------- Props ----------------
   */
  props: {
    /**
     * Top header title styles
     */
    titleStyle: {
      type: String,
      default: "pb-8",
    },
    /**
     * Height oof the card wrapper
     */
    height: {
      type: [String, Number],
      default: HEIGHT,
    },
    /**
     * Width of the wrapper
     */
    width: {
      type: [String, Number],
      default: WIDTH,
    },
  },
  /**
   * ---------------- Computed properties ----------------
   */
  computed: {
    ...mapGetters({
      isSessionExpiredRoute: "ui/isSessionExpiredRoute",
    }),
    /**
     * showTermsAndConditions
     * Determines whether to show the terms and condition link to the user
     * @type {Boolean}
     */
    showTermsAndConditions() {
      return !this.isSessionExpiredRoute(this.$route);
    },
    /**
     * Determines of the display is desktop
     * @type {Boolean}
     */
    isDesktop() {
      const { name } = this.$vuetify.breakpoint;
      const { lg, md, xl } = DISPLAY_BREAKPOINTS;

      return [lg, md, xl].includes(name);
    },
    /**
     * Computes the dimensions of the auth wrapper card element
     * @type {Object}
     */
    cardDimensions() {
      const MOBILE_WIDTH = "350px";

      return {
        width: this.isDesktop ? this.width : MOBILE_WIDTH,
        height: this.isDesktop ? this.height : null,
      };
    },
  },
};
</script>
