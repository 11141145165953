<template>
  <component :is="layout"></component>
</template>

<script>
import { AppLayout, AuthLayout } from "@/layouts";
import VuexPersist from "@/config/vuex.persist.config";

/**
 * Main root component of the application
 */
export default {
  name: "App",
  /**
   * ---------------- Components ------------------
   */
  components: {
    AppLayout,
    AuthLayout,
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    /**
     * Fetches current route's layout
     */
    layout() {
      const { meta } = this.$route;
      const { app, auth } = this.$appConfig.layouts;

      return `${this.isLoggedIn() ? meta.layout ?? app : auth}Layout`;
    },
  },
  /**
   * ---------------- On component mount ------------------
   */
  mounted() {
    this.removeSplashLoader();
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    /**
     * Determines whether a user is logged in or not
     * @returns {Boolean}
     */
    isLoggedIn() {
      const { vuexPersist } = VuexPersist;
      return (
        JSON.parse(localStorage.getItem(vuexPersist.key))?.auth?.isLoggedIn ??
        false
      );
    },
    /**
     * Removes splash screen loader when application is successfully loaded on the browser
     */
    removeSplashLoader() {
      const [SPLASH_ELEMENT, DISPLAY_NONE] = ["splash", "none"];
      document.getElementById(SPLASH_ELEMENT).style.display = DISPLAY_NONE;
    },
  },
};
</script>
