function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{ref:"navigation",attrs:{"app":"","clipped":"","hide-overlay":"","permanent":_vm.$vuetify.breakpoint.mdAndUp,"mini-variant":_vm.showMini,"mini-variant-width":60},on:{"update:miniVariant":function($event){_vm.showMini=$event},"update:mini-variant":function($event){_vm.showMini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{class:{ 'nav-drawer-list': _vm.isMobile },attrs:{"dense":""}},[_vm._l((_vm.navItems),function(ref,i){
var icon = ref.icon;
var text = ref.text;
var to = ref.to;
var query = ref.query;
var elementId = ref.elementId;
var rest$1 = objectWithoutProperties( ref, ["icon", "text", "to", "query", "elementId"] );
var rest = rest$1;
return [_c('v-list-item',{key:(i + "_" + text),staticClass:"nav--link subtitle-2",class:[rest.class, _vm.drawer ? 'pr-4' : 'pr-1'],attrs:{"link":"","exact-path":"","to":{ path: to, query: query },"active-class":"nav--link-active","id":elementId},on:{"click":function($event){_vm.selectedLink = to}}},[_c('tooltip',{attrs:{"name":text,"disabled":!_vm.isDrawerOpen,"position":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({class:[
              { 'mr-4': !_vm.drawer },
              { 'nav-icon-responsive': _vm.isMobile } ]},'v-list-item-icon',attrs,false),on),[_c('v-icon',{staticClass:"text-dark-grey",domProps:{"textContent":_vm._s(icon)}})],1)]}}],null,true)}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2 text-uppercase font-weight-bold secondary--font overflow-visible",class:{ 'pr-1': !_vm.drawer && _vm.selectedLink !== to }},[_vm._v(" "+_vm._s(text)+" ")])],1)],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }