<template>
  <div id="breadcrumbs">
    <span v-for="(route, i) in routes" :key="i">
      <span v-if="i > 0" class="blue--text text--darken-3 mx-1 body-2">
        {{ getSeperator(route.type) }}
      </span>
      <!-- Router link starts -->
      <router-link
        v-if="route.to"
        class="body-2 blue--text text--darken-3"
        :to="{ path: route.to, query: route.query }"
      >
        {{ route.title }}
      </router-link>
      <!-- Router link ends -->

      <span
        v-if="!route.to"
        class="font-primary font-weight-regular text-black--80 body-2"
      >
        {{ route.title }}
      </span>
    </span>
  </div>
</template>

<script>
import $appConfig from "@/config/app.config";

/**
 * Display the breadcrumbs
 */
export default {
  name: "Breadcrumbs",
  props: {
    /**
     * Routes to be diaplayed in the breadcrumb
     */
    routes: {
      type: Array,
      required: true,
    },
  },
  /**
   * Type of breadcrumbs to be displayed
   */
  type: {
    type: String,
    default: $appConfig.breadcrumbs.types.route,
  },
  methods: {
    /**
     * Computes the seperator for the breadcrumbs based upon route type
     * @type {String}
     *  @values route, name
     */
    getSeperator(type) {
      const isTypeRoute = type === $appConfig.breadcrumbs.types.route;
      const seperator = isTypeRoute ? ">" : "-";
      return seperator;
    },
  },
};
</script>
 