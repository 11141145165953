import { $http } from "./apis/http/http";
import apiEndpoints from "@/constants/api-endpoints";

/**
 * Fetches the list of the countries
 * @param {Object} params Api query params
 * @returns {Promise} Promise containing list of the countries
 */
export const getCountries = (params) => {
  return $http.get(apiEndpoints.countries, params);
};

/**
 * Fetches the list of the state of a country
 * @param {String} country Name of the country whose states are to be fetched
 * @returns {Promise} Promise containing the list of the state of a country
 */
export const getStates = (country) => {
  const statesEndpoint = `/countries/${country}`;
  return $http.get(statesEndpoint);
};

/**
 * Fetches the list of timezones
 * @returns {Promise} Promise containing the list of the timezones
 */
export const getTimezones = () => {
  return $http.get(apiEndpoints.timezones);
};
