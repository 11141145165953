/**
 * Vuetify colors scheme to be used in the layout
 */
const vuetifyColors = {
  green: "#41CE65",
  white: "#FFFFFF",
  grey: "#BBBBBB",

  black: "#1E2533",
  lightGrey: "#f5f5f5",
  background:'#FBFBFB'
};

/**
 * Vuetify global theme configuration for the inboxaware application
 */
const vuetifyConfig = {
  theme: {
    dark: false,
    light: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: vuetifyColors.green,
        anchor: vuetifyColors.white,
        white: vuetifyColors.white,
        grey: vuetifyColors.grey,
        "light-grey": vuetifyColors.lightGrey,
        background: vuetifyColors.background,
        black: vuetifyColors.black,
      },
    },
  },
};

export default Object.freeze(vuetifyConfig);
