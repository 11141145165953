import $paths from "@/constants/routes-path.js";

/**
 * Auth routes
 */
export default [
  {
    path: $paths.login,
    name: "login",
    meta: {
      layout: "Auth",
    },
    component: () => import("@/views/auth/Login.vue"),
  },
  {
    path: $paths.forgotPassword,
    name: "forgot-password",
    meta: {
      layout: "Auth",
    },
    component: () => import("@/views/auth/ForgotPassword.vue"),
  },
  {
    path: $paths.resetPasswordConfirmation,
    name: "forgot-password-confirmation",
    meta: {
      layout: "Auth",
    },
    component: () => import("@/views/auth/ForgotPasswordConfirmation.vue"),
  },
  {
    path: $paths.resetPassword,
    name: "reset-password",
    meta: {
      layout: "Auth",
    },
    component: () => import("@/views/auth/ResetPassword.vue"),
  },
  {
    path: $paths.sessionExpired,
    name: "session-expired",
    meta: {
      layout: "Auth",
    },
    component: () => import("@/views/auth/SessionExpired.vue"),
  },
  {
    path: $paths.acceptInvitation,
    name: "accept-invitation",
    meta: {
      layout: "Auth",
    },
    component: () => import("@/views/auth/AcceptInvitation.vue"),
  },
  {
    path: $paths.loggedOut,
    name: "logged-out",
    meta: {
      layout: "Auth",
    },
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "logout" */"@/views/auth/LoggedOut.vue"),
  },
];
