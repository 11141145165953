import { apiEndpoints } from "@/constants";
import { $accounts } from "@/services/apis/accounts";

/**
 * Fetches list of sending ips of a campaign
 * @param {String} id id of an account
 * @param {String} campaignId id of the camapign whose sending ips are to be fetched
 * @returns {Promise} List of sending ips
 */
export const getSendingIps = ({ account_id, campaign_id, ...params }) => {
  const endpoint = `${account_id}/${apiEndpoints.accountCampaigns}/${campaign_id}/${apiEndpoints.sendingIPs}`;
  return $accounts.get(endpoint, { params });
};
