import $paths from "@/constants/routes-path.js";

/**
 * Account settings routes
 */
export default [
  {
    path: $paths.accountSettings,
    name: "account-settings",
    meta: {
      layout: "App",
      requiresAuth: true,
    },
    component: () => import("@/views/settings/AccountSettings.vue"),
  },
];
