<template>
  <div class="date-range-picker d-flex">
    <!-- Start Date Picker  -->
    <v-menu
      v-model="startDatePicker"
      :close-on-content-click="false"
      attach
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="startDate"
          label="From Date"
          outlined
          class="pr-2"
          readonly
          v-bind="attrs"
          v-on="on"
          append-icon="mdi-calendar"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="startDate"
        no-title
        scrollable
        color="primary"
        :max="endDate"
        @input="onChangeHandler"
      >
      </v-date-picker>
    </v-menu>
    <!-- End Date Picker -->
    <v-menu
      v-model="endDatePicker"
      :close-on-content-click="false"
      :nudge-right="-150"
      transition="scale-transition"
      offset-y
      attach
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="endDate"
          label="To Date"
          outlined
          readonly
          v-bind="attrs"
          v-on="on"
          append-icon="mdi-calendar"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="endDate"
        no-title
        color="primary"
        scrollable
        :min="startDate"
        @input="onChangeHandler"
      >
      </v-date-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "DateRangePicker",

  /**
   * ------------- Props attribute ----------------
   */
  props: {
    dates: {
      type: Array,
      required: true,
    },
  },

  /**
   * -------------- Data attributes ---------------
   */
  data() {
    return {
      startDatePicker: false,
      endDatePicker: false,
      startDateValue: null,
      endDateValue: null,
    };
  },

  /**
   * -----------------Computed property --------------
   */
  computed: {
    startDate: {
      get() {
        return this.startDateValue ?? this.dates[0];
      },
      set(value) {
        this.startDateValue = value;
      },
    },

    endDate: {
      get() {
        const endDate =
          this.dates && this.dates.length > 1 ? this.dates[1] : null;
        return this.endDateValue ?? endDate;
      },
      set(value) {
        this.endDateValue = value;
      },
    },
  },

  /**
   * ---------------- Custom event emitted ---------------
   */
  emits: ["input"],

  /**
   * -----------------Methods ----------------------
   */
  methods: {
    /**
     * on Change emit event
     * @emit input
     */
    onChangeHandler() {
      this.$emit("input", [this.startDate, this.endDate]);
    },
  },
};
</script>