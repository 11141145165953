import { $accounts } from "./apis/accounts";
import apiEndpoints from "@/constants/api-endpoints";
import { getEndpoint } from "@/utils/api.utils";

/**
 * Fetches mailbox providers trends by ip address
 * api is deployed on the staging enviroment
 * @param {Object} params - Api params
 * @returns {Promise} Api response mailbox provider details
 */
export const ipAddressTrends = ({ account_ids, ...params }) => {
  const endpoint = getEndpoint(account_ids, apiEndpoints.ipAddressTrends);
  return $accounts.get(endpoint, { params });
};

/**
 * Fetches email address trends from the api resource
 * @param {Object} params - Api params
 * @returns {Promise} APi response
 */
export const mailboxProviderTrends = ({ account_ids, ...params }) => {
  const { mailboxProvidersTrends } = apiEndpoints;
  const endpoint = getEndpoint(account_ids, mailboxProvidersTrends);

  return $accounts.get(endpoint, { params });
};

/**
 * Fetches seed domain inbox trends from the api resource
 * @param {Object} params - Api params
 * @returns {Promise} APi response
 */
export const seedDomainTrends = ({ account_ids, ...params }) => {
  const endpoint = getEndpoint(account_ids, apiEndpoints.seedDomainTrends);
  return $accounts.get(endpoint, { params });
};
