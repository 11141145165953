<template>
  <v-fade-transition>
    <v-overlay v-show="loading" absolute z-index="1" :color="overlayColor">
      <v-progress-circular
        absolute
        indeterminate
        class="darken-1"
        :size="size"
        :color="$appConfig.loader.color"
      />
    </v-overlay>
  </v-fade-transition>
</template>
<script>
import appConfig from "@/config/app.config";

/**
 * Renders loader
 */
export default {
  name: "Loader",
  props: {
    /**
     * Shows and hide the loader
     */
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    /**
     * Backgroud color of the overlay
     */
    overlayColor: {
      type: String,
      default: "white",
    },
    /**
     * Size of the loader
     */
    size: {
      type: Number,
      default: appConfig.loader.size,
    },
  },
};
</script>
