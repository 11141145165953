import $paths from "../../constants/routes-path.js";
import settings from "./account-settings.routes.js";
import inboxRoutes from "./inbox.routes.js";
import authRoutes from "./auth.routes";

/**
 * All the routes of the application
 * @description import all the routes in this file
 * and spread them into an array of all the routes of the application
 */
export default [
  {
    path: $paths.dashboard,
    name: "dashboard",
    meta: {
      layout: "App",
      requiresAuth: true,
    },
    component: () => import("@/views/Dashboard.vue"),
  },
  ...authRoutes,
  ...settings,
  ...inboxRoutes,
];
