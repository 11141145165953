/**
 * Month names constants
 */
export const MONTHS = Object.freeze({
  month_names: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  month_names_short: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
});

/**
 * Constants for the Node enviroments in the application
 */
export const NODE_ENVS = {
  dev: "development",
  test: "test",
};

/**
 * Common api respose codes
 */
export const RESPONSE_CODES = {
  success: 200,
  badRequest: 400,
  unAuthorised: 401,
  notFound: 404,
  internalServerError: 500,
  created: 201,
  noContent: 204,
};

/**
 * Table sorting orders
 */
export const SORTING_ORDERS = {
  desc: "desc",
  asc: "asc",
};

/**
 * Table per page options
 */
export const TABLE_PER_PAGES_OPTIONS = [5, 10, 25, 50, 100];

/**
 * Default mailbox providers list
 */
export const DEFAULT_MAILBOX_PROVIDERS = [
  { seed_domain: "gmail.com", name: "Gmail" },
  { seed_domain: "outlook.com", name: "Outlook.com" },
  { seed_domain: "yahoo.com", name: "Yahoo" },
  { seed_domain: "aol.com", name: "AOL" },
];

/**
 * Chart widgets for chart datasets
 */
export const CHART_DATASET_COLORS = {
  purple: "#F53BAD",
  green: "#41CE65",
  yellow: "#FFBF00",
  yahoo: "#03B6FC",
  white: "#ffffff",
  black: "rgba(0, 0, 0, 0.87)",
};

/**
 * Roles object
 * @description Do not change the order of roles object properties this may effect the application
 */
export const ROLES = {
  user: "user",
  admin: "admin",
  owner: "owner",
};

/**
 * Gip auth error codes
 */
export const AUTH_GIP_ERROR_CODES = {
  userNotFound: "auth/user-not-found",
  wrongPassword: "auth/wrong-password",
  accountNotFound: "auth/account-not-found",
};

/**
 * Table metrics tile colors
 */
export const TABLE_METRICS_TILE_COLORS = {
  green: "#41CE65",
  yellow: "#FFBF00",
  magenta: "#F53B57",
  darkBlue: "#3D40C6",
};

/**
 * Mailbox provider metrics constant
 */
export const MAILBOX_PROVIDER_METRICS = {
  inbox_count: "inbox_count",
  spam_count: "spam_count",
  missing_count: "missing_count",
};

/**
 * Inbox rate trends dashboard widget
 */
export const INBOX_RATE_TREND_WIDGETS = [
  { name: "Mailbox Provider", component: "MailboxProviderTrend" },
  { name: "IP Address", component: "MailboxProvidersIpTrends" },
  { name: "From Domain", component: "SeedDomainTrends" },
];

/**
 * Display breakpoints
 */
export const DISPLAY_BREAKPOINTS = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
};

/**
 * Inbox rate trend metrics
 */
export const INBOX_RATE_TREND_METRICS = [
  {
    name: "Inbox %",
    value: "inbox_rate",
  },
  { name: "Spam %", value: "spam_rate" },
  { name: "Missing %", value: "missing_rate" },
];

/**
 * Csv file label to be ingnored
 */
export const IGNORE_CSV_LABEL = "actions";

/**
 * Emails mailbox type
 */
export const MAILBOX_PROVIDER_TYPES = ["spam", "missing", "inbox"];

/**
 * Default items per page in table
 */
export const DEFAULT_ITEMS_PER_PAGE = TABLE_PER_PAGES_OPTIONS[1];
