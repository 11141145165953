import store from "@/store";
import { RESPONSE_CODES } from "@/constants/app";
import { errors as errorMessages } from "@/dictionary";
import $appConfig from "@/config/app.config";
import { capitalize } from "@/filters";
import { isEmpty } from "@/utils/common.utils";
import { isCancelRequest } from "@/utils/api.utils";

const { snackbar } = $appConfig;

/**
 * Intercepts all api request which are sent using the axios instance globally
 * @param {Object} config request
 * @returns Api request
 */
export const requestInterceptor = async (config) => {
  try {
    let token = store.getters["auth/token"];
    let isTokenExpired = store.getters["auth/isTokenExpired"]();

    if (isTokenExpired) {
      await store.dispatch("auth/regenerateUserToken");
      token = store.getters["auth/token"];
    }

    // Adds the token in the request header if token is present in teh store
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  } catch {
    return config;
  }
};

/**
 * Iterates over error object and concats error messages into a single string
 * @param {Object} errors Object containing api error messages
 * @param {Object} snackbarError Object refference to be used to show error message in the snackbar
 */
const showErrorMessage = (errors, snackbarError) => {
  let message = errorMessages.common.msg;
  const showSnackbar = !store.getters["ui/hideSnackbar"];

  if (!isEmpty(errors)) {
    message = Object.keys(errors).reduce((acc, prop) => {
      return acc.concat(`${capitalize(prop)} ${errors[prop][0] ?? ""}`, " ");
    }, "");
  }
  // Fallback common error message to be shown to the user

  snackbarError.message = message;
  if (showSnackbar) store.dispatch("ui/setSnackbar", snackbarError);
};

/**
 * Intercepts all the api response error returned by the api's
 */
export const responseInterceptor = (error) => {
  let isUnauthorised = false;
  let snackbarError = {
    value: true,
    message: "",
    type: snackbar.snackbarTypes.error,
  };

  if (error.response) {
    const statusCode = error.response?.status;
    const { data } = error.response;

    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    switch (statusCode) {
      /**
       * Dispacthes an action in the store to logout the user if 401 status code
       */
      case RESPONSE_CODES.unAuthorised:
        if (!store.getters["account/areAccountsLoading"]) {
          isUnauthorised = true;
          store.dispatch("auth/logoutUser", isUnauthorised);
        }
        break;

      /**
       * Handles errors of (404) status code
       */
      case RESPONSE_CODES.notFound:
        showErrorMessage(data, snackbarError);
        break;

      /**
       * Handles (400) error response case
       */
      case RESPONSE_CODES.badRequest:
      case RESPONSE_CODES.internalServerError:
        showErrorMessage(data, snackbarError);
        break;
    }
  } else if (!isCancelRequest(error)) {
    /**
     * Handles error case for network error
     * @description dispatched an action in the store to mutate the state of snackbar in the ui module
     */
    snackbarError.message = error.message;
    store.dispatch("ui/setSnackbar", snackbarError);
  }
  return !isUnauthorised && Promise.reject(error);
};
