import Vue from "vue";
import Vuex from "vuex";
import vuexPersist from "@/plugins/vuex-persist";

import * as modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules,
  devtools: true,
  plugins: [vuexPersist.plugin],
});
