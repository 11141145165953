function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"inboxaware-app","app":"","clipped-right":"","clipped-left":"","color":"primary","tag":"header","light":""}},[_c('v-app-bar-nav-icon',{attrs:{"slot":"default","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggle-drawer')}},slot:"default"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-menu ")])],1),_c('v-toolbar-title',{staticClass:"align-center overflow-visible",class:{ 'mr-12': !_vm.$vuetify.breakpoint.xsOnly }},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.$paths.dashboard}},[_c('div',{staticClass:"white--text",class:{ 'mt-1': !_vm.$vuetify.breakpoint.xsOnly }},[_c('v-img',{class:_vm.$vuetify.breakpoint.smAndDown ? 'inboxaware-logo' : '',attrs:{"src":_vm.$vuetify.breakpoint.smAndDown
              ? _vm.$images.navHeaderMobileLogo
              : _vm.$images.navHeaderLogo,"alt":"InboxAware Logo"}})],1)])],1),_c('v-spacer'),_c('v-toolbar-items',[_c('account-filter'),_c('v-menu',{attrs:{"offset-y":"","value":_vm.isMenuOpen},on:{"input":function($event){_vm.isMenuOpen = !_vm.isMenuOpen}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-capitalize text-spaced--0 pl-4 pr-3",attrs:{"text":"","id":"top-right__dropdown"}},on),[_c('v-icon',{staticClass:"pr-1",attrs:{"medium":"","color":"white"}},[_vm._v(" mdi-account-circle ")]),_c('span',{staticClass:"d-none d-sm-flex white--text subtitle-2 secondary--font font-weight-large"},[_vm._v(" "+_vm._s(_vm._f("truncateText")(_vm.userFullName,40))+" ")]),_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.isMenuOpen ? "mdi-menu-up" : "mdi-menu-down"))])],1)]}}])},[_c('v-list',{staticClass:"w-100 py-1",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":""}},[_vm._l((_vm.listItems),function(ref){
            var name = ref.name;
            var icon = ref.icon;
            var elementId = ref.elementId;
            var to = ref.to;
            var hide = ref.hide;
            var href = ref.href;
            var targetBlank = ref.targetBlank;
            var rest = objectWithoutProperties( ref, ["name", "icon", "elementId", "to", "hide", "href", "targetBlank"] );
            var prop = rest;
return [(!prop.hide)?_c('v-list-item',{key:name,staticClass:"px-5 text-black--80",class:{ 'd-none': hide },staticStyle:{"min-height":"36px !important"},attrs:{"link":"","to":to,"href":href,"target":targetBlank && 'blank',"id":elementId,"dense":"","disabled":_vm.isDisabled(to)}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{staticClass:"text-dark-grey",domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-content',{staticClass:"pl-3 text-capitalize body-2 text--primary"},[_vm._v(" "+_vm._s(name)+" ")])],1):_vm._e()]}),_c('v-list-item',{staticClass:"px-5",on:{"click":_vm.logout}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v(_vm._s(_vm.logoutAction.icon))])],1),_c('v-list-item-content',{staticClass:"pl-3"},[_c('v-list-item-title',{staticClass:"text-capitalize body-2 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.logoutAction.name)+" ")])],1)],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }