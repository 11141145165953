import axios from "axios";
import apiConfig from "@/config/api.config";
import { requestInterceptor, responseInterceptor } from "../http/interceptors";

/**
 * Axios instance to be used to fetch accounts resources
 */
export const $accounts = axios.create({
  baseURL: apiConfig.ACCOUNTS_API_BASE_URL,
});

/**
 * Intercepts all the accounts resource api requests
 */
$accounts.interceptors.request.use(requestInterceptor);

/**
 * Intercepts accounts api response
 * @todo Create a sperate response interceptor in the ./interceptors file to handle the api resposne of accounts resources
 */
$accounts.interceptors.response.use(undefined, responseInterceptor);
