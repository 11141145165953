import errors from "@/dictionary/errors";

/**
 * Calculates the sum of the array of records
 * @param {Array} records Records whose sum is to be calculated
 * @returns {Number} Sum of the records
 */
export const sum = (records) => {
  if (!Array.isArray(records) || records?.length === 0) return 0;
  return records.reduce((prev, cur) => prev + cur, 0);
};

/**
 * Analysis the error object and returns the error message or default error message
 * @param {Object} error Error object
 * @returns {String} Error message to be shown to the user
 */
export const errorMessage = (error) => {
  return (error && error?.message) || errors.common.msg;
};
