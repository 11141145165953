import {
  TABLE_PER_PAGES_OPTIONS,
  SORTING_ORDERS,
  INBOX_RATE_TREND_WIDGETS,
  INBOX_RATE_TREND_METRICS,
} from "@/constants";
import cloneDeep from "lodash/cloneDeep";
import { getPropArr, updateStore } from "@/utils/common.utils";

const [
  MAILBOX_PROVIDER_WIDGET,
  IP_ADDRESS_WIDGET,
  SENDING_DOMAINS_WIDGET,
] = INBOX_RATE_TREND_WIDGETS;
const [INBOX_RATE] = INBOX_RATE_TREND_METRICS;

/** Initial selected metric value */

const defaultSelectedMetric = {};
INBOX_RATE_TREND_WIDGETS.forEach(({ component }) => {
  defaultSelectedMetric[component] = { ...INBOX_RATE };
});

/** Initial state of the module */
const initialState = {
  // Dashboard user settings
  dashboard: {
    selectedDay: 7,
    selectedMaxCampaigns: 5,
    recentCampaigns: {
      sort_by: null,
      sort_order: SORTING_ORDERS.desc,
    },
    inboxRateTrendWidget: {
      selectedMetric: defaultSelectedMetric,
      activeWidget: MAILBOX_PROVIDER_WIDGET,
      filters: { domains: [], ip_addresses: [], sending_domains: [] },
      hasSendingIpsFilter: false,
      hasSendingDomainsFilter: false,
      areTrendsLoading: false,
    },
  },
  // Campaign view settings
  campaign: {
    table: {
      sort_by: "first_seen",
      sort_order: SORTING_ORDERS.desc,
      itemsPerPage: 10,
      filters: {},
      itemsPerPageOptions: TABLE_PER_PAGES_OPTIONS,
    },
  },

  // Seed Email Deliverability view settings
  emailSeeds: {
    sort_by: "seed_address",
    sort_order: SORTING_ORDERS.asc,
  },
  // Mailbox Provider view settings
  mailboxProvider: {
    sort_by: "mailbox_provider",
    sort_order: SORTING_ORDERS.asc,
    itemsPerPage: TABLE_PER_PAGES_OPTIONS[2],
    itemsPerPageOptions: TABLE_PER_PAGES_OPTIONS,
    filters: {},
    areFiltersApplied: false,
  },

  // Manage seed view settings
  seeds: {
    sort_by: null,
    sort_order: null,
    itemsPerPage: 10,
    itemsPerPageOptions: TABLE_PER_PAGES_OPTIONS,
  },

  // User view settings
  users: {
    sort_by: "email",
    sort_order: SORTING_ORDERS.asc,
    itemsPerPage: 10,
    itemsPerPageOptions: TABLE_PER_PAGES_OPTIONS,
  },
  mailboxProvidersChanged: false,
  areTrendFiltersLoading: false,
};

/**
 * State
 * @description This module manages,stores and persits the user selected settings
 * in the application ui screens
 */
const state = { ...cloneDeep(initialState) };

/**
 * Mutation constants
 */
const SET_DASHBOARD_SETTINGS = "SET_DASHBOARD_SETTINGS";
const SET_DASHBOARD_RECENT_CAMPAIGNS = "SET_DASHBOARD_RECENT_CAMPAIGNS";
const SET_CAMPAIGN_TABLE_SETTINGS = "SET_CAMPAIGN_SETTINGS";
const SET_EMAIL_SEEDS_SETTINGS = "SET_EMAIL_SEEDS_SETTINGS";
const SET_SEEDS_SETTINGS = "SET_SEEDS_SETTINGS";
const SET_MAILBOX_PROVIDER_SETTINGS = "SET_MAILBIX_PROVIDER_SETTINGS";
const SET_USERS_SETTINGS = "SET_USERS_SETTINGS";
const REMOVE_SETTINGS = "REMOVE_SETTINGS";
const SET_INBOX_RATE_TREND_WIDGET_SETTINGS =
  "SET_INBOX_RATE_TREND_WIDGET_SETTINGS";
const SET_INBOX_RATE_TREND_ACTIVE_WIDGET = "SET_INBOX_RATE_TREND_ACTIVE_WIDGET";
const SET_TRENDS_LOADING = "SET_TRENDS_LOADING";
const SET_MAILBOX_PROVIDERS_CHANGED = "SET_MAILBOX_PROVIDERS_CHANGED";
const RESET_ACTIVE_TREND_WIDGET_FILTER = "RESET_ACTIVE_TREND_WIDGET_FILTER";
const SET_TREND_WIDGET_FILTERS_LOADING = "SET_TREND_WIDGET_FILTERS_LOADING";

/**
 * Mutations
 */
const mutations = {
  [SET_DASHBOARD_SETTINGS](state, data) {
    const updatedSettings = { ...state.dashboard, ...data };
    state.dashboard = updatedSettings;
  },
  [SET_DASHBOARD_RECENT_CAMPAIGNS](state, data) {
    const updatedSettings = { ...state.dashboard.recentCampaigns, ...data };
    state.dashboard.recentCampaigns = updatedSettings;
  },
  [SET_CAMPAIGN_TABLE_SETTINGS](state, data) {
    const updatedSettings = { ...state.campaign.table, ...data };
    state.campaign.table = updatedSettings;
  },
  [SET_EMAIL_SEEDS_SETTINGS](state, data) {
    const updatedSettings = { ...state.emailSeeds, ...data };
    state.emailSeeds = updatedSettings;
  },
  [SET_MAILBOX_PROVIDER_SETTINGS](state, data) {
    const updatedSettings = { ...state.mailboxProvider, ...data };
    state.mailboxProvider = updatedSettings;
  },
  [SET_SEEDS_SETTINGS](state, data) {
    const updatedSettings = { ...state.seeds, ...data };
    state.seeds = updatedSettings;
  },
  [SET_USERS_SETTINGS](state, data) {
    const updatedSettings = { ...state.users, ...data };
    state.users = updatedSettings;
  },
  /**
   * Sets the ui user selected settings to defaul when user logouts
   */
  [REMOVE_SETTINGS](state) {
    const isOldUserLoggedInAgain = this.getters["auth/isOldUserLoggedInAgain"];
    if (isOldUserLoggedInAgain) return;

    const newState = cloneDeep(initialState);
    /** Iterates over state properties and reinitalises the properties with the default settings properties values */
    Object.keys(state).forEach((prop) => (state[prop] = newState[prop]));
  },
  [SET_INBOX_RATE_TREND_WIDGET_SETTINGS]({ dashboard }, data) {
    let filters = updateStore(
      dashboard.inboxRateTrendWidget?.filters,
      data?.filters
    );

    const updatedSettings = updateStore(dashboard.inboxRateTrendWidget, {
      ...data,
      filters,
    });
    dashboard.inboxRateTrendWidget = updatedSettings;
  },
  [SET_INBOX_RATE_TREND_ACTIVE_WIDGET]({ dashboard }, { activeWidget }) {
    let defaultData = { activeWidget, filters: {}, hasSendingIpsFilter: false };

    switch (activeWidget?.name) {
      case MAILBOX_PROVIDER_WIDGET.name:
        defaultData.filters = { domains: [] };
        defaultData.hasSendingIpsFilter = false;
        defaultData.hasSendingDomainsFilter = false;
        break;

      case IP_ADDRESS_WIDGET.name:
        defaultData.filters = {
          domains: [],
          ip_addresses: [],
        };
        defaultData.hasSendingIpsFilter = true;
        defaultData.hasSendingDomainsFilter = false;
        break;

      case SENDING_DOMAINS_WIDGET.name:
        defaultData.filters = { sending_domains: [] };
        defaultData.hasSendingDomainsFilter = true;
        defaultData.hasSendingIpsFilter = false;
        break;
    }

    const updatedSettings = updateStore(
      dashboard.inboxRateTrendWidget,
      defaultData
    );
    dashboard.inboxRateTrendWidget = updatedSettings;
  },
  [SET_TRENDS_LOADING](
    { dashboard: { inboxRateTrendWidget } },
    areTrendsLoading
  ) {
    inboxRateTrendWidget.areTrendsLoading = areTrendsLoading;
  },
  [SET_MAILBOX_PROVIDERS_CHANGED](state, data) {
    state.mailboxProvidersChanged = data;
  },
  [RESET_ACTIVE_TREND_WIDGET_FILTER](state) {
    const { activeWidget } = state.dashboard.inboxRateTrendWidget;
    let providerWidgets = getPropArr(INBOX_RATE_TREND_WIDGETS, "name");

    // Only set persisted filters of first 2 widgets to empty object if one of them is active
    providerWidgets = providerWidgets.slice(0, 2);
    const updateProviderFilter = providerWidgets.includes(activeWidget?.name);

    // Updates the persisted filters of active dashoboard widget to empty
    if (updateProviderFilter) {
      state.dashboard.inboxRateTrendWidget.filters = {};
    }
  },
  [SET_TREND_WIDGET_FILTERS_LOADING](state, data) {
    state.areTrendFiltersLoading = data;
  },
};

/**
 * Actions
 */
const actions = {
  updateDashboardSettings({ commit }, settings) {
    commit(SET_DASHBOARD_SETTINGS, settings);
  },
  setDashboardSettings({ commit }, settings) {
    commit(SET_DASHBOARD_RECENT_CAMPAIGNS, settings);
  },
  setCampaigntTableSettings({ commit }, settings) {
    commit(SET_CAMPAIGN_TABLE_SETTINGS, settings);
  },
  setEmailSeedsSettings({ commit }, settings) {
    commit(SET_EMAIL_SEEDS_SETTINGS, settings);
  },
  setMailboxProviderSettings({ commit }, settings) {
    commit(SET_MAILBOX_PROVIDER_SETTINGS, settings);
  },
  setSeedsSettings({ commit }, settings) {
    commit(SET_SEEDS_SETTINGS, settings);
  },
  setUsersSettings({ commit }, settings) {
    commit(SET_USERS_SETTINGS, settings);
  },
  removeSettings({ commit }) {
    /** Commented the mutation so that selected account is persisted when user logins again  */
    // commit("ui/REMOVE_ACCOUNT", null, { root: true });
    commit(REMOVE_SETTINGS);
  },
};
/**
 * Getters
 */
const getters = {
  dashboard({ dashboard }) {
    return dashboard;
  },
  campaign({ campaign }) {
    return campaign;
  },
  emailseeds({ emailSeeds }) {
    return emailSeeds;
  },
  mailboxProvider({ mailboxProvider }) {
    return mailboxProvider;
  },
  seeds({ seeds }) {
    return seeds;
  },
  users({ users }) {
    return users;
  },
  inboxRateTrendWidget({ dashboard: { inboxRateTrendWidget } }) {
    return inboxRateTrendWidget;
  },
  areTrendsLoading({ dashboard: { inboxRateTrendWidget } }) {
    return inboxRateTrendWidget.areTrendsLoading;
  },
  areMailboxProvidersChanged({ mailboxProvidersChanged }) {
    return mailboxProvidersChanged;
  },
  areTrendFiltersLoading({ areTrendFiltersLoading }) {
    return areTrendFiltersLoading;
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
};
