import store from "@/store";
import { isEmpty } from "@/utils/common.utils";
import { getAccount } from "@/services/accounts";

/**
 * Auth router middleware
 * @description before each route, check if user needs auth
 * @param {Object} to - the route to change to
 * @param {Object} from - the previous route
 * @param {Object} next - callback
 */
const auth = async (to, from, next) => {
  const { getters } = store;
  const isUserLoggedIn = getters["auth/isLoggedIn"];
  const selectedAccount = getters["ui/selectedAccount"];

  const isRouteAuthorised = to.matched.some(({ meta }) => meta.requiresAuth);

  // Redirects user to login page if there are no routes matched in the application
  if (isEmpty(to?.matched)) {
    next({ name: "login" });
  }
  // Redirect user to login page If current route is authorised and user is not logged in
  else if (to.name !== "login" && !isUserLoggedIn && isRouteAuthorised)
    next({ name: "login" });
  // Redirects user to dashboard page if user is logged in and route is not defined
  else if ((!to.name || to.name === "login") && isUserLoggedIn) {
    next({ name: "dashboard" });
  } else {
    /** Fetches account details of the account on the basis of the route id (account id) param */
    if (
      to?.params?.id &&
      Number(to?.params?.id) !== selectedAccount?.account_id
    ) {
      try {
        const { data } = await getAccount({ account_id: to?.params?.id });
        store.dispatch("ui/setAccount", data);
      } catch {
        next();
      }
    }
    next();
  }
};

export default {
  auth,
};
