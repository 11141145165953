<template>
  <div class="snackbar-wrapper">
    <!-- Snackbar starts -->
    <v-snackbar
      top
      centered
      multi-line
      content-class="py-3"
      :color="config.bgColor"
      v-model="showSnackbar"
      :timeout="$appConfig.snackbar.timeout"
    >
      <!-- Snackbar content to be displayed here -->
      <div class="my-2 mx-2 snackbar-content d-flex">
        <v-icon class="mr-3" :color="config.iconColor">
          {{ config.icon }}
        </v-icon>

        <div class="text--primary d-flex align-center">
          <slot :config="{ ...config }" />
        </div>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          plain
          :color="config.bgColor"
          v-bind="attrs"
          @click="closeSnackbar(!snackbarValue)"
        >
          <v-icon
            class="ml-auto cursor-pointer"
            :color="closeBtnConfig.iconColor"
          >
            {{ snackBarIcon || closeBtnConfig.icon }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Snackbar Ends -->
  </div>
</template>

<script>
/**
 * Displays snackbar and dynamic message on the basis of the props
 */
export default {
  name: "Snackbar",
  /**
   * Custom events to be emitted by the component
   */
  emits: ["close"],
  props: {
    /**
     * Value of the snackbar
     * @model
     */
    snackbarValue: {
      type: Boolean,
      default: false,
    },
    snackBarIcon: {
      type: String,
      default: "mdi-close",
    },
    /**
     * Type of snackbar to be displayed
     *  @values success, error, info
     */
    snackBarType: {
      type: String,
      required: true,
    },
  },
  // ------------- Computed properties
  computed: {
    /**
     * The value for snackbar
     * @description the value gets calculated on the basis of the {snackbarValue} props
     * @type {Boolean}
     * @model snackbar v-model
     */
    showSnackbar: {
      get() {
        return this.snackbarValue;
      },
      set(val) {
        this.closeSnackbar(val);
      },
    },
    /**
     * Close snackbar button config
     */ closeBtnConfig() {
      return this.$appConfig.snackbar.config.close;
    },
    /**
     * Snackbar configuration on the basis of the snackbar type prop
     * @values success, error, info - These 3 snackbar types are supported
     * @type {Object}
     */
    config() {
      const { config } = this.$appConfig.snackbar;
      if (!this.snackBarType) return config.info;

      return config[this.snackBarType];
    },
  },
  methods: {
    /**
     * Emits an custom event in the parent component to close the snackbar
     * @param {Boolean} val - new value of the snackbar
     * @description gets called when the user clicks the close button in snackbar
     * @emits close
     */
    closeSnackbar(val) {
      this.$emit("close", val);
    },
  },
};
</script>
