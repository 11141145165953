/**
 * Env config
 */
const ENV_CONFIG = process.env;

/**
 * Firebase configrations
 */
const firebaseConfig = {
  apiKey: ENV_CONFIG.VUE_APP_FIREBASE_API_KEY,
  authDomain: ENV_CONFIG.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: ENV_CONFIG.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: ENV_CONFIG.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: ENV_CONFIG.VUE_APP_FIREBASE_MESSENGING_SENDER_ID,
  appId: ENV_CONFIG.VUE_APP_FIREBASE_APP_ID,
  measurementId: ENV_CONFIG.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

export default Object.freeze(firebaseConfig);
