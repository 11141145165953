/**
 * Error messages
 */
const errors = {
  common: {
    msg: "Something went wrong",
  },
  auth: {
    invalidPassword: "The password is invalid",
    emailNotFound: "User not found",
    accountNotFound: "Sorry, there is no account with that email address.",
  },
};

export default Object.freeze(errors);
