<template>
  <v-row no-gutters id="inbox-rate-trends-mailbox-provider" class="py-5 pb-6">
    <v-col lg="8" md="8">
      <ul class="d-flex overflow-auto pl-0">
        <li
          v-for="({ label, hidden }, index) in defaultMailboxProviders"
          :key="label"
          class="card-tile pr-8"
        >
          <div class="d-flex align-items-center">
            <span
              class="body-2 text--secondary pr-2"
              :class="[
                { 'text-capitalize': legendTextCapitalize },
                {
                  'text-decoration-line-through text-decoration-secondary': hidden,
                },
              ]"
            >
              {{ label }}
            </span>

            <v-avatar
              tile
              v-ripple="{ class: 'white--text' }"
              :color="legendDatasetColors[index]"
              height="16px"
              width="48px"
              class="rounded cursor-pointer"
              @click="toogleGraphData(index)"
            />
          </div>
          <div class="text-left headline primary-font d-block">
            <h2 class="font-weight-large text-black--80 text-h5 mt-2">
              {{ trendsPercentages[index] }}%
            </h2>
          </div>
        </li>
      </ul>
    </v-col>
    <v-col lg="4" md="4" align-self="end">
      <v-tabs v-model="selectedMetric" right>
        <template v-for="{ name, value } in metrics">
          <v-tab
            active-class="primary--text"
            class="subtitle-2 text-dark-grey border-bottom-grey"
            :key="value"
          >
            {{ name }}
          </v-tab>
        </template>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import { CHART_DATASET_COLORS } from "@/constants/app";

/** Legend colors */
const legendDatasetColors = Object.values(CHART_DATASET_COLORS);
legendDatasetColors.length = 4;

/**
 * Chart widgets legend
 */
export default {
  name: "WidgetLegend",
  /**
   * -------------- Props -------------
   */
  props: {
    defaultMailboxProviders: { type: Array, required: true },
    trendsPercentages: { type: Array, required: true },
    metrics: { type: Array, required: true },
    metric: { type: Number, required: true },
    chartRef: { required: true },
    legendTextCapitalize: { type: Boolean, default: false },
  },
  /**
   * -------------- Props -------------
   */
  data: () => ({
    legendDatasetColors,
  }),
  /**
   * -------------- Computed -------------
   */
  computed: {
    /**
     * Selected metric model
     * @model
     */
    selectedMetric: {
      get() {
        return this.metric;
      },
      /**
       * Sets new value of the selected metric
       * @emits selected-metric
       */
      set(value) {
        this.$emit("selected-metric", value);
      },
    },
  },
  /**
   * -------------- Methods -------------
   */
  methods: {
    /**
     * Hide and show data graphs in the chart
     */
    toogleGraphData(index) {
      const ref = this.chartRef._data._chart;
      var meta = ref.getDatasetMeta(index);

      meta.hidden =
        meta.hidden === null ? !ref.data.datasets[index].hidden : null;

      const { hidden } = this.defaultMailboxProviders[index];
      this.defaultMailboxProviders[index].hidden = !hidden;

      ref.update();
    },
  },
};
</script>
<style lang="scss" scoped>
.card-tile {
  list-style: none !important;
}

.text-decoration-secondary {
  text-decoration-color: $dark--grey !important;
}
</style>
