<template>
  <div id="auth-layout">
    <v-app>
      <v-main id="main-section">
        <v-container fluid class="py-0 px-0">
          <v-row no-gutters>
            <v-col
              cols="12"
              id="auth__wrapper"
              :md="!isAuthExpiredRoute ? 6 : 12"
              :lg="!isAuthExpiredRoute ? 6 : 12"
              :class="{ 'auth-session-expired-bg ': isAuthExpiredRoute }"
              class="d-flex align-center justify-center"
            >
              <div id="auth__top-logo" v-if="!isAuthExpiredRoute">
                <router-link :to="$paths.login" title="Login">
                  <v-img
                    width="49px"
                    height="46px"
                    :src="$images.logo"
                    alt="Inboxaware logo"
                  />
                </router-link>
              </div>
              <!--------------- Renders vue routes  --------------->
              <transition name="fade" mode="out-in">
                <router-view />
              </transition>
            </v-col>
            <v-col
              lg="6"
              md="6"
              cols="12"
              class="align-center d-none d-md-flex d-lg-flex"
              id="auth-background"
              v-if="!isAuthExpiredRoute"
            >
              <div id="auth-background-content">
                <img
                  id="auth-inboxaware-logo"
                  :src="$images.coluredLogo"
                  alt="Inboxaware auth logo"
                />
                <img
                  id="auth-inboxaware-background"
                  :src="$images.inboxAuthBackground"
                  alt="Inboxaware auth background"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <snackbar
        :snackbarValue="snackbar.value"
        :snackBarIcon="snackbar.icon"
        :snackBarType="snackbar.type"
        @close="closeSnackbar"
      >
        {{ snackbar.message }}
      </snackbar>
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

/**
 * Auth layout to be shown when user is not logged or is unauthorised
 */
export default {
  name: "AuthLayout",
  /**
   * ---------- Computed properties ------------
   */
  computed: {
    ...mapGetters({
      snackbar: "ui/snackbar",
      isSessionExpiredRoute: "ui/isSessionExpiredRoute",
    }),
    /**
     * isAuthExpiredRoute
     * Determines if the current route is of session expired view or logged out view
     * @type {Boolean}
     */
    isAuthExpiredRoute() {
      return this.isSessionExpiredRoute(this.$route);
    },
  },
  /**
   * -------------- Methods -------------
   */
  methods: {
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * Closes the snackbar and dispactches the action in the store
     */
    closeSnackbar(value) {
      const data = { value, message: "" };

      this.setSnackbar(data);
    },
  },
};
</script>

<style lang="scss" scoped>
#main-section {
  background-color: $layout-background-color;
  .row {
    min-height: 100vh !important;
  }
}

#auth__wrapper {
  #auth__top-logo {
    position: absolute;
    top: 4%;
    left: 4%;
  }
}

.auth-session-expired-bg {
  height: 100vh;
  background-image: url("/img/inboxaware-logged-out-background.dc4eb143.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.w-50vm {
  width: 50vw !important;
}

#auth-background {
  height: 100vh !important;
  overflow: hidden;
  position: fixed;
  right: 0px;
  @extend .w-50vm;
  #auth-background-content {
    position: relative;

    #auth-inboxaware-logo {
      position: absolute;
      top: calc(50vh - 75px);
      left: calc(25vw - 75px);
    }
    #auth-inboxaware-background {
      height: 100vh !important;
      @extend .w-50vm;
      display: block !important;
    }
  }
}
</style>
