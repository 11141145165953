<template>
  <div>
    <v-row align="center">
      <v-col class="py-0">
        <template v-if="breadcrumbs">
          <breadcrumbs :routes="breadcrumbs" />
        </template>
      </v-col>
    </v-row>
    <v-row no-gutters class="d-flex justify-end">
      <!-- Slot to render the title -->
      <slot name="title"></slot>
      <slot name="header"></slot>

      <v-col cols="12" md="6" class="d-flex align-center justify-end">
        <div class="mb-sm-2">
          <!-- Slot to render the  -->
          <slot name="filters"></slot>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/**
 * Render the header of the table listing views
 */
export default {
  props: {
    /**
     * View top breadcrumbs navigations to be rendered
     */
    breadcrumbs: {
      type: Array,
    },
  },
};
</script>