import firebase from "firebase/app";
import { $http } from "./apis/http/http";
import { apiEndpoints } from "@/constants";

/**
 * Firebase auth service
 */
export const auth = firebase.auth();

/**
 * signInWithEmailAndPassword
 * @param {Object} credentials Credentials of the user who is trying to login
 * @returns {Promise}
 */
export const signInWithEmailAndPassword = ({ email, password }) => {
  return auth.signInWithEmailAndPassword(email, password);
};

/**
 * Fetches the Jwt token of trh current login user
 * @returns {String} Jwt token of the current user
 */
export const getCurrentUserIdToken = () => {
  return auth?.currentUser?.getIdToken();
};

/**
 * Fetches the logged in user details
 * @returns {Object} Logged in user details
 */
export const getCurrentUser = () => auth.currentUser;

/**
 * Signout logged in user from firebase auth
 * @returns {Promise}
 */
export const signOutUser = () => auth.signOut();

/**
 * Reauthenticates the current user with fresh credentials
 * @param {String} param0
 * @returns Promise
 */
export const reauthenticateUserWithCredential = ({ email, password }) => {
  // Creates the credential of the user
  const credential = firebase.auth.EmailAuthProvider.credential(
    email,
    password
  );
  // Now using the credentials for reauthentication
  return firebase.auth().currentUser.reauthenticateWithCredential(credential);
};

/**
 * Resets the user password
 * @param {String} password New password of the user
 * @returns {Promise}
 */
export const resetPassword = (password) => {
  return getCurrentUser().updatePassword(password);
};

/**
 * Sends reset email to the user whise password is to reset
 * @param {String} email Account email whom to send the reset email
 * @returns {Promise}
 */
export const resetPasswordEmail = (email) => {
  return $http.post(apiEndpoints.resetPassword, { email });
};

/**
 * Verifies teh oobCode or token sent to teh user email id to reset the password
 * @param {String} oobCode Code or token to be verified before updating the user password
 * @returns {Promise} with email address of the user where the reset password code or token was sent
 * @author {Jatin Kamboj}
 */
export const verifyPasswordResetTokenOrOobCode = (oobCode) => {
  return auth.verifyPasswordResetCode(oobCode);
};

/**
 * Verifies the OobCode and chnages the user password on the basis of the OobCode sent to the user email address
 * @param {String} oobCode OobCode or token sent to the user email address
 * @param {String} newPassword New password which is to updated for the user account
 * @returns {Promise} Password changed successfully or an error code with error message
 * @author {Jatin Kamboj}
 */
export const confirmOobCodeAndResetPassword = (oobCode, newPassword) => {
  return auth.confirmPasswordReset(oobCode, newPassword);
};

/**
 * Fetches the list of sign in methods of a created user in the gip
 * @param {String} email Email of the user whom sign in methods are to be fetched
 * @returns {Array} Array of string which contains signin methods of the user
 */
export const fetchSignInMethodsForEmail = (email) => {
  return auth.fetchSignInMethodsForEmail(email);
};

/**
 * Creates google auth provider instance which is to be used to authenticate a user
 * @returns {GoogleAuthProvider} Google auth provider
 */
export const googleAuthProvider = () => new firebase.auth.GoogleAuthProvider();

/**
 * Redirects user to auth provider page to authenticate the user
 * @param {Object} provider Sign in provider (Third party) to be used to sign in and authenticate the user
 * @returns {Promise}
 */
export const signInWithAuthProviderPopup = (provider) => {
  return auth.signInWithPopup(provider);
};

/**
 * Fetches logged in user profile
 * @returns {Promise} Returns logged in user profile
 */
export const getLoggedInUserProfile = () => {
  return $http.get(apiEndpoints.profile);
};

/**
 * Update logged in user profile details
 * @returns {Promise} Returns logged in user updated profile details
 */
export const updateLoggedInUserProfile = (params) => {
  return $http.patch(apiEndpoints.profile, params);
};

/**
 * Fetches current logged in user metadata
 * @returns {Object} Metadata of the user
 */
export const getCurrentUserMetadata = () => {
  return auth?.currentUser?.metadata;
};
