import {
  signOutUser,
  getCurrentUser,
  googleAuthProvider,
  getCurrentUserIdToken,
  signInWithAuthProviderPopup,
  signInWithEmailAndPassword,
  reauthenticateUserWithCredential,
  getLoggedInUserProfile,
  auth,
  getAccount,
  getCurrentUserMetadata,
} from "@/services";
import router from "@/router";
import { $paths, RESPONSE_CODES, ROLES, SORTING_ORDERS } from "@/constants";
import {
  isEmpty,
  isType,
  pick,
  toTimezone,
  updateStore,
} from "@/utils/common.utils";
import appConfig from "@/config/app.config";
import { restrictUser } from "@/utils/shared.utils";
import { rejectPromise } from "@/utils";
import dayjs from "dayjs";

/**
 * Module state
 */
const state = {
  currentUser: null,
  token: null,
  isLoggedIn: false,
  currentUserProfile: null,
  currentUserAccess: [],
  currentUserMetaData: null,
  tokenExpiryTime: null,
  isTokenRefreshing: false,
  oldLoggedInUserDetails: {
    oldUsedId: "",
    currentUsedId: "",
  },
};

/**
|--------------------------------------------------
| Mutations constants
|--------------------------------------------------
*/
const SET_CURRENT_USER = "SET_CURRENT_USER";
const LOG_OUT_USER = "LOG_OUT_USER";
const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
const SET_CURRENT_USER_PROFILE = "GET_CURRENT_USER_PROFILE";
const SET_CURRENT_USER_ACCESS = "GET_CURRENT_USER_ACCESS";
const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
const SET_CURRENT_USER_METADATA = "SET_CURRENT_USER_METADATA";
const SET_AUTH_TOKEN_EXPIRY_TIME = "SET_AUTH_TOKEN_EXPIRY_TIME";
const SET_IS_TOKEN_REFRESHING = "SET_IS_TOKEN_REFRESHING";
const SET_OLD_LOGGED_IN_USER_DETAILS = "SET_OLD_LOGGED_IN_USER_DETAILS";

/**
|--------------------------------------------------
| Mutations
|--------------------------------------------------
*/
const mutations = {
  [SET_CURRENT_USER](state, data) {
    state.currentUser = { ...state.currentUser, ...data };
    state.isLoggedIn = true;
  },
  /** Mutation to logout the user */
  [LOG_OUT_USER](state) {
    state.token = null;
    state.isLoggedIn = false;
    state.currentUser = null;
    state.currentUserProfile = null;
    state.currentUserMetaData = null;
    state.isTokenRefreshing = false;
  },
  [UPDATE_CURRENT_USER](state, data) {
    state.currentUser = { ...state.currentUser, ...data };
  },
  [SET_CURRENT_USER_PROFILE](state, data) {
    state.currentUserProfile = data;
  },
  [SET_CURRENT_USER_ACCESS](state, data) {
    state.currentUserAccess = data;
  },
  [SET_AUTH_TOKEN](state, data) {
    state.token = data;
  },
  [SET_CURRENT_USER_METADATA](state, data) {
    state.currentUserMetaData = updateStore(state.currentUserMetaData, data);
  },
  [SET_AUTH_TOKEN_EXPIRY_TIME](state, loginTimestamp) {
    const expiryTime = dayjs(loginTimestamp)
      .add(3600, "seconds")
      .toString();
    state.tokenExpiryTime = expiryTime;
  },
  [SET_IS_TOKEN_REFRESHING](state, data) {
    state.isTokenRefreshing = data;
  },
  [SET_OLD_LOGGED_IN_USER_DETAILS](state, data) {
    state.oldLoggedInUserDetails = updateStore(
      state.oldLoggedInUserDetails,
      data
    );
  },
};

/**
|--------------------------------------------------
| Actions
|--------------------------------------------------
*/
const actions = {
  /**
   * Sends an ajax request to the authorise the user on the basis of the credentials
   * commits a mutation to save the user details in the store
   * @param {Object} credentials User credentials who wants to login
   */
  async signInUser({ dispatch }, credentials) {
    try {
      let { user } = await signInWithEmailAndPassword(credentials);
      const token = await getCurrentUserIdToken();
      const userDetails = await getCurrentUser();

      user = {
        ...pick(user, ["email", "expiresIn", "refreshToken", "token", "uid"]),
        ...pick(userDetails, ["phoneNumber", "photoURL", "displayName"]),
      };

      await dispatch("fetchUserDetails", { token, user });

      router.push({ path: $paths.dashboard });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * fetchUserDetails
   * @description Fetches user details such as whether user have access to any accounts in the application
   * If user have access to accounts then first account is setted as a selected account by default
   */
  async fetchUserDetails(
    { commit, dispatch, getters, rootState: { account, ui } },
    { user, token }
  ) {
    const { asc } = SORTING_ORDERS;
    const accountParams = { per_page: 1, page: 1, "q[s]": `name ${asc}` };

    await commit(SET_AUTH_TOKEN, token);
    await dispatch("account/getAccounts", accountParams, { root: true });
    dispatch("getUserMetadata");
    await commit(SET_OLD_LOGGED_IN_USER_DETAILS, { currentUsedId: user?.uid });

    if (!getters.isOldUserLoggedInAgain) {
      await commit("settings/REMOVE_SETTINGS", null, { root: true });
    }

    // If user have access to accounts then first account is set as selected account globally
    if (account.hasAccountAccess) {
      let selectedAccount;
      // Verifies if the newly logged in user have access to the selected account which is persisted in the localstorage
      if (ui?.selectedAccount?.account_id) {
        try {
          await commit("ui/HIDE_SNACKBAR", true, { root: true });
          const { data } = await getAccount(
            pick(ui?.selectedAccount, ["account_id"])
          );
          // If user have access to the persisted account
          if (!isEmpty(data)) selectedAccount = data;
        } catch (error) {
          const isNotFound =
            error?.response?.status === RESPONSE_CODES.notFound;
          if (isNotFound && !isEmpty(account.accounts)) {
            [selectedAccount] = account.accounts;
          }
        }
      } else {
        [selectedAccount] = account.accounts;
      }

      commit("ui/HIDE_SNACKBAR", false, { root: true });

      const accountIndex = account.accounts.findIndex(
        ({ id }) => id === selectedAccount?.id
      );
      await dispatch(
        "ui/setAccount",
        updateStore(selectedAccount, account.accounts[accountIndex] ?? {}),
        { root: true }
      );
    } else if (!account.hasAccountAccess) {
      restrictUser(commit);
    }

    await dispatch("getCurrentUserProfile");
    await commit(SET_CURRENT_USER, user);
  },

  /**
   * Commits a mutation to log out the user from the application
   * and redirectd the user to the login page
   * @param {Boolean} isUnauthorised - Determines whether user is unauthorised or not on the basis of 401 error code
   */
  async logoutUser({ commit, dispatch, state }, isUnauthorised = false) {
    // Path of the view where to redirect the user
    const path = !isUnauthorised ? $paths.loggedOut : $paths.sessionExpired;

    await signOutUser();
    await commit(SET_OLD_LOGGED_IN_USER_DETAILS, {
      oldUsedId: state.currentUser?.uid,
    });
    await commit(LOG_OUT_USER);
    await dispatch(
      "ui/setSnackbar",
      { value: false, message: "" },
      { root: true }
    );
    await commit("account/SET_USER_HAS_ACCOUNT_ACCESS", false, { root: true });
    setTimeout(
      () => dispatch("settings/removeSettings", null, { root: true }),
      1000
    );

    if (router.app.$route.path !== path) router.push({ path });
  },

  /**
   * Fetches the latest details of the logged in user
   */
  async getCurrentUserDetails({ dispatch }) {
    const userDetails = await getCurrentUser();
    const { displayName, photoURL, phoneNumber } = userDetails;
    const details = { displayName, photoURL, phoneNumber };

    dispatch("updateCurrentUser", details);
  },

  /**
   * Re autheicates the logged in user
   * @param {Object} param1 User credentials to be used to reauthenticate
   * @returns
   */
  async reauthenticateUser({ dispatch }, { email, password }) {
    try {
      const { user } = await reauthenticateUserWithCredential({
        email,
        password,
      });
      const { refreshToken } = user;

      dispatch("updateCurrentUser", { refreshToken });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * Updates the current user
   * @param {Object} details Details of the logged in user to be updated
   */
  updateCurrentUser({ commit }, details) {
    commit(UPDATE_CURRENT_USER, details);
  },

  /**
   * Fetches the curren logged in user profile details
   */
  async getCurrentUserProfile(
    {
      commit,
      state: { currentUser },
      rootState: {
        ui: { selectedAccount },
      },
    },
    userDetails = {}
  ) {
    try {
      const account_id = userDetails?.account_id ?? selectedAccount?.account_id;
      const account_name = selectedAccount?.account_name;

      const { data } = await getLoggedInUserProfile();
      let user = data ?? {};

      commit(SET_CURRENT_USER_PROFILE, user);
      //passing user and account objects:
      const aptrinsic = window.aptrinsic;
      if (isType(aptrinsic, "function")) {
        aptrinsic(
          "identify",
          {
            //User Fields
            id: currentUser?.uid,
            email: user.email,
            firstName: user.first_name,
            lastName: user.last_name,
            signUpDate: new Date(user.updated_at)?.getTime(), //unix time in ms
          },
          {
            //Account Fields
            id: account_id,
            name: account_name,
          }
        );
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * Sign in user with google auth provider
   */
  async signInUserWithGoogle({ dispatch }) {
    try {
      // To apply the default browser preference instead of explicitly setting it.
      auth.useDeviceLanguage();

      /*eslint-disable */
      let { credential, user } = await signInWithAuthProviderPopup(
        googleAuthProvider()
      );
      const token = await getCurrentUserIdToken();

      /**
       * The signed-in user info.
       * Commented credentials code for future auth use
       */
      user = {
        ...pick(user, ["displayName", "email", "photoURL"]),
        // ...pick(credential, ["accessToken", "idToken"]),
      };

      await dispatch("fetchUserDetails", { token, user });
      router.push({ path: $paths.dashboard });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * Fetches logged in user GIP meta data
   */
  async getUserMetadata({ commit }) {
    try {
      const metadata = await getCurrentUserMetadata();
      commit(SET_CURRENT_USER_METADATA, metadata);
      commit(SET_AUTH_TOKEN_EXPIRY_TIME, metadata?.lastSignInTime);
    } catch (error) {
      return rejectPromise(error);
    }
  },
  async getGipUserObject({ dispatch }) {
    /**
     * Fetches current logged in firebase user object
     */
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        unsubscribe();
        try {
          if (user && !isEmpty(user)) {
            resolve(user);
          } else {
            dispatch("logoutUser");
          }
        } catch (error) {
          reject(error);
        }
      }, reject);
    });
  },
  /**
   * Regenerate user token if the token of the user had been expired
   */
  async regenerateUserToken({ state, dispatch, commit, getters }) {
    try {
      await commit(SET_IS_TOKEN_REFRESHING, true);
      const user = await dispatch("getGipUserObject");
      const token = await user?.getIdToken();
      const lastSignInTime = new Date();

      await commit(SET_AUTH_TOKEN, token);
      await commit(SET_AUTH_TOKEN_EXPIRY_TIME, lastSignInTime);
      await commit(SET_CURRENT_USER_METADATA, { lastSignInTime });
    } catch (error) {
      return Promise.reject(error);
    } finally {
      commit(SET_IS_TOKEN_REFRESHING, false);
    }
  },
};

/**
|--------------------------------------------------
| Module getters
|--------------------------------------------------
*/
const getters = {
  isLoggedIn({ isLoggedIn }) {
    return isLoggedIn;
  },
  token({ token }) {
    return token;
  },
  currentUser({ currentUser }) {
    return currentUser;
  },
  currentUserProfile({ currentUserProfile }) {
    return currentUserProfile;
  },

  isMaropostUser({ currentUser }) {
    return (
      currentUser?.email &&
      currentUser.email.includes(appConfig.maropostEmailDomain)
    );
  },
  isRole: (state, getters, rootState) => (role) => {
    const { ui } = rootState;
    return ui?.selectedAccount?.role === role;
  },
  isAdmin(state, { isRole }) {
    return isRole(ROLES.admin) || isRole(ROLES.owner);
  },
  hasAccountsAccess() {
    const [, , { account }] = arguments;
    return account.hasAccountAccess;
  },
  /**
   * Determines whether the JWT token have expired or not
   * @type {Boolean}
   */
  isTokenExpired: ({ tokenExpiryTime }) => () => {
    const currentTime = dayjs();

    tokenExpiryTime = dayjs(tokenExpiryTime);
    return tokenExpiryTime.isValid() && currentTime.isAfter(tokenExpiryTime);
  },
  isOldUserLoggedInAgain({ oldLoggedInUserDetails }) {
    const { oldUsedId, currentUsedId } = oldLoggedInUserDetails;
    return oldUsedId === currentUsedId;
  },
  timezone({ currentUserProfile }) {
    return currentUserProfile?.time_zone ?? "Etc/UTC";
  },
  toUserTimezone: (...storeData) => (date) => {
    const [, { timezone }] = storeData;
    return toTimezone(date, timezone);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
