<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      scrollable
      :width="width"
      :max-width="maxWidth"
    >
      <!-- Renders child elements -->
      <slot> </slot>
    </v-dialog>
  </v-row>
</template>

<script>
import { isType } from "@/utils/common.utils";

/**
 * Common dialog box
 */
export default {
  name: "DialogBox",
  /**
   * -------------- Props -------------
   */
  props: {
    dialog: {
      required: true,
      type: Boolean,
      default: false,
    },
    width: { type: String, default: "50%" },
    maxWidth: { type: String, default: "50%" },
  },
  /**
   * -------------- Custom events -------------
   */
  emits: {
    close: function (val) {
      if (val && isType(val, "boolean")) return true;
      console.warn("Val required param is missing.");
      return false;
    },
  },
  /**
   * -------------- Computed Properties -------------
   */
  computed: {
    /** Property based upon the parent props  */
    showDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        /**
         * @description emits dialog val to the parent
         */
        this.closeDialog(value);
      },
    },
  },
  /**
   * -------------- Methods -------------
   */
  methods: {
    /**
     * Emits event in parent component to close dialog box
     */
    closeDialog(val) {
      this.$emit("close", val);
    },
  },
};
</script>
