var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"py-5 pb-6",attrs:{"no-gutters":"","id":"inbox-rate-trends-mailbox-provider"}},[_c('v-col',{attrs:{"lg":"8","md":"8"}},[_c('ul',{staticClass:"d-flex overflow-auto pl-0"},_vm._l((_vm.defaultMailboxProviders),function(ref,index){
var label = ref.label;
var hidden = ref.hidden;
return _c('li',{key:label,staticClass:"card-tile pr-8"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"body-2 text--secondary pr-2",class:[
              { 'text-capitalize': _vm.legendTextCapitalize },
              {
                'text-decoration-line-through text-decoration-secondary': hidden,
              } ]},[_vm._v(" "+_vm._s(label)+" ")]),_c('v-avatar',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'white--text' }),expression:"{ class: 'white--text' }"}],staticClass:"rounded cursor-pointer",attrs:{"tile":"","color":_vm.legendDatasetColors[index],"height":"16px","width":"48px"},on:{"click":function($event){return _vm.toogleGraphData(index)}}})],1),_c('div',{staticClass:"text-left headline primary-font d-block"},[_c('h2',{staticClass:"font-weight-large text-black--80 text-h5 mt-2"},[_vm._v(" "+_vm._s(_vm.trendsPercentages[index])+"% ")])])])}),0)]),_c('v-col',{attrs:{"lg":"4","md":"4","align-self":"end"}},[_c('v-tabs',{attrs:{"right":""},model:{value:(_vm.selectedMetric),callback:function ($$v) {_vm.selectedMetric=$$v},expression:"selectedMetric"}},[_vm._l((_vm.metrics),function(ref){
            var name = ref.name;
            var value = ref.value;
return [_c('v-tab',{key:value,staticClass:"subtitle-2 text-dark-grey border-bottom-grey",attrs:{"active-class":"primary--text"}},[_vm._v(" "+_vm._s(name)+" ")])]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }