<template>
  <div
    class="account-filter"
    :class="$vuetify.breakpoint.xsOnly ? 'account-filter-width' : ''"
  >
    <!-- Selected Account Section -->
    <div @click="toggleSelect" v-ripple class="black--text">
      <div
        class="
          body-2
          cursor-pointer
          white--text
          font-weight-large
          text-right
          py-5
          secondary--font 
        "
        :class="{ 'd-flex': isMobile($vuetify) }"
      >
        <v-icon color="white" v-text="'mdi-map-marker'" />
        <span
          v-text="accountsPlaceholder"
          :class="$vuetify.breakpoint.smAndDown ? 'selected-account' : ''"
        />
        <v-icon color="white" v-text="menuIcon" />
      </div>
    </div>
    <!-- End Selected account section -->
    <v-select
      md3
      class="
        account-dropdown-header
        py-2
        secondary--font
        font-weight-large
        subtitle-2
        white--text
      "
      single-line
      hide-details
      color="white"
      flat
      ref="select"
      solo
      :background-color="$vuetify.theme.themes.light.primary"
      v-model="selectedAccountId"
      :items="accountList"
      return-object
      item-text="account_name"
      item-value="id"
      prepend-inner-icon="mdi-map-marker"
      :menu-props="menuProps"
      :placeholder="accountsPlaceholder"
    >
      <template v-slot:prepend-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              dense
              hide-details
              ref="searchInputField"
              prepend-inner-icon="mdi-magnify"
              outlined
              :loading="isLoading"
              v-model="searchTerm"
              label="Search Accounts"
              @input="searchAccount"
            />
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #item="{ item, on, attrs }">
        <v-list-item
          class="account-list__item"
          style="min-height: 36px !important"
          @change="onSelectAccount(item)"
          v-on="on"
          v-bind="attrs"
        >
          <v-list-item-content class="body-2 text-black--80">
            {{ item.account_name }}
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #no-data>
        <v-list-item>
          <v-list-item-content class="body-2 text--primary">
            {{ $dictionary.app.appHeader.accountsFilter.noResults }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
import { getAccounts } from "@/services";
import { defer, isEmpty, isMobile } from "@/utils/common.utils";
import { mapActions, mapGetters, mapMutations } from "vuex";

import cloneDeep from "lodash/cloneDeep";
import { sortString } from "@/utils/shared.utils";

/**
 * Accounts filter dropsown to serach the accounts
 * And manage the whole application on the basis of the account seleted
 * @author {Jatin Kamboj}
 */
export default {
  name: "AccountFilter",
  /**
   * ---------------- Data attributes ------------------
   */
  data() {
    return {
      searchTerm: "",
      accountList: [],
      accounts: [],
      isLoading: false,
      isSearching: false,
      hasRecords: true,
      accountsCurrentPage: 1,
    };
  },

  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      selectedAccount: "ui/selectedAccount",
    }),
    /**
     * Selected account id
     */
    selectedAccountId: {
      get() {
        return this.selectedAccount?.account_id;
      },
      set(val) {
        return val;
      },
    },

    /**
     * Get dropdown menu icon
     */
    menuIcon: {
      cache: false,
      get: function() {
        return this.$refs.select?.isMenuActive
          ? "mdi-menu-up"
          : "mdi-menu-down";
      },
    },
    /**
     * Set menu-props of select dropdown
     */
    menuProps() {
      return {
        bottom: true,
        offsetY: true,
        maxWidth: "auto",
        on: {},
      };
    },
    /**
     * Accounts select dropdown placeholder to be shown if there is any account selected or not
     * @type {String}
     */
    accountsPlaceholder() {
      const ACCOUNTS = "Accounts";
      return this.selectedAccount
        ? this.selectedAccount.account_name
        : ACCOUNTS;
    },
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    isMobile,
    ...mapActions({
      setAccount: "ui/setAccount",
      setSnackbar: "ui/setSnackbar",
      getAccountDetails: "ui/getAccountDetails",
      getCurrentUserProfile: "auth/getCurrentUserProfile",
    }),
    ...mapMutations({
      setSeletedAccountChanged: "ui/SET_SELECTED_ACCOUNT_CHANGED",
      updateDashboardFilters: "settings/RESET_ACTIVE_TREND_WIDGET_FILTER",
    }),
    /**
     * Search account from list
     */
    searchAccount(val) {
      this.accountList = this.accounts.filter((acc) => {
        return acc.account_name.toLowerCase().indexOf(val.toLowerCase()) > -1;
      });
    },
    /**
     * selectedAccountChange
     * @param {String} account_id Account id
     * @description Commits a mutation in the store if the selected account have been changed
     * on other pages except for the dashboard page
     */
    async selectedAccountChange(account_id) {
      const isDashboard = this.$route.path === this.$paths.dashboard;
      const isSelectedAccountDiff =
        account_id !== this.selectedAccountId?.account_id;

      if (isSelectedAccountDiff) {
        await this.setSeletedAccountChanged(isSelectedAccountDiff);
        !isDashboard && this.updateDashboardFilters();
      }
    },
    /**
     * Updates selected account details
     * Redirect the user to campaigns page
     */
    async onSelectAccount({ id, ...data }) {
      await this.setAccount({ id, ...data });

      this.selectedAccountChange(id);

      this.$route.path?.includes(this.$paths.campaigns) &&
        this.$router.push({
          path: this.selectedAccount.path,
        });
      this.$refs.select.isFocused = false;
    },
    /**
     * Fetches accounts list from the api
     */
    async getAccountsList(params = this.params) {
      try {
        this.isLoading = true;
        let { data } = await getAccounts(params);
        data = data ?? [];

        this.accountList = data.sort(sortString("account_name"));
        this.accounts = cloneDeep(this.accountList);
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: error?.message,
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
      } finally {
        this.isLoading = false;
        this.isSearching = false;
      }
    },
    /**
     * Adds selected account in the accounts list of the selected account is not present
     * in the list so that the selected account can be persisted
     */
    addSelectedAccountOnLoad() {
      const { account_id } = this.selectedAccount;

      if (!isEmpty(this.accountList) && account_id) {
        const hasAccount = this.accountList.findIndex(
          (account) => account.id === account_id
        );

        hasAccount === -1 && this.accountList.push({ ...this.selectedAccount });
      }
    },
    /**
     * Toggle select (open/close select menu)
     */
    toggleSelect() {
      const el = this.$refs.select.$el;
      if (!this.$refs.select.isMenuActive) {
        this.$refs.select.onClick(el);
        defer(() => this.$refs?.searchInputField?.focus(), 100);
      } else {
        this.$refs.select.onBlur(el);
      }
    },
  },
  async created() {
    await this.getAccountsList();
    this.addSelectedAccountOnLoad();
  },
};
</script>
<style lang="scss" scoped>
.account-dropdown-header {
  max-width: fit-content !important;
  .v-select__selection {
    max-width: 100% !important;
  }
}
.account-dropdown-header::v-deep .v-input__control {
  visibility: hidden !important;
  margin-top: -3.5rem;
}
.selected-account {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.account-filter-width {
  max-width: 10rem;
}
</style>
