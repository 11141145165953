import uniqBy from "lodash/uniqBy";
import { duration, formatDateToAmPm } from "@/filters";
import { AUTH_GIP_ERROR_CODES, MAILBOX_PROVIDER_TYPES } from "@/constants/app";
import { errors } from "@/dictionary";
import { isEmpty } from "./common.utils";

/**
 * Sorts mailbox providers in asecending order
 */
const sortMailboxProvider = (a, b) => {
  a = a?.mailbox_provider.toLowerCase();
  b = b?.mailbox_provider.toLowerCase();
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

/**
 * Sort mailbox providers in to ascending order on the basis of date
 * @returns {Array} Sorted mailbox provider seeds
 */
const sortMailboxProviderSeeds = (seeds) => {
  return seeds?.sort((a, b) => {
    const firstSeed = b.received_at ?? b.created_at;
    const secondSeed = a.received_at ?? a.created_at;
    return new Date(firstSeed) - new Date(secondSeed);
  });
};

/**
 * Computes the mailbox provider stats on the basis of email mailbox type
 */
export const getMailboxProviderStats = (emails) => {
  if (isEmpty(emails)) return [];
  const UNIQ_PROP = "seed_domain";

  const getPercent = (from, total) =>
    from && total ? Number(((from / total) * 100).toFixed(2)) : from;

  const mailboxProviderStats = uniqBy(emails, UNIQ_PROP).map((email) => {
    // Seeds of a mailbox provider
    let mailboxProviderSeeds = emails.filter(
      ({ seed_domain }) => seed_domain === email.seed_domain
    );

    // Total seed count of mailbox provider
    let seedCount = mailboxProviderSeeds.length ?? 0;

    // Iterate over mailboxTypes here
    MAILBOX_PROVIDER_TYPES.forEach((type) => {
      // Find total seeds of a maibox type (spam, missing, inbox)
      let typeCount =
        mailboxProviderSeeds.filter(({ mailbox_type }) => mailbox_type === type)
          .length ?? 0;

      email[`${type}_count`] = typeCount ?? 0;
      return (email[type] = typeCount ? getPercent(typeCount, seedCount) : 0);
    });

    mailboxProviderSeeds = mailboxProviderSeeds.filter(
      ({ sent_at, received_at }) => !!sent_at && !!received_at
    );

    // Get first email seed for current mailbox provider
    const firstSeed = sortMailboxProviderSeeds(mailboxProviderSeeds);

    return {
      ...email,
      duration: duration(
        firstSeed?.[0]?.sent_at,
        firstSeed.at(-1)?.received_at
      ),
      received_at: formatDateToAmPm(email.received_at),
      first_seen: formatDateToAmPm(
        !isEmpty(firstSeed)
          ? firstSeed[0]?.received_at ?? firstSeed[0]?.created_at
          : ""
      ),
    };
  });

  return mailboxProviderStats.sort(sortMailboxProvider);
};

/**
 * Sorts an array of ip address
 */
export const sortIpAddress = function(a, b) {
  const SPLITTER = ".";
  a = a.ip_address.split(SPLITTER);
  b = b.ip_address.split(SPLITTER);

  for (var i = 0; i < a.length; i++) {
    if ((a[i] = parseInt(a[i])) < (b[i] = parseInt(b[i]))) return -1;
    else if (a[i] > b[i]) return 1;
  }
  return 0;
};

/**
 * Restrict an user if user trying to login into teh application does not have
 * access to any account
 * @throws {Error} Throws error with error message
 */
export const restrictUser = (commit) => {
  const error = {
    code: AUTH_GIP_ERROR_CODES.accountNotFound,
    message: errors.auth.accountNotFound,
  };
  commit("auth/SET_AUTH_TOKEN", null, { root: true });

  throw error;
};

/**
 * @description Sorts an array of objects on the basis of a string property
 * defined in the object
 * @param {String} prop Property defined in the object on the basis of which data array have to be sorted
 * @returns {Function} Sort function
 */
export const sortString = (prop) => {
  return (a, b) => {
    let x = a[prop].toLowerCase();
    let y = b[prop].toLowerCase();
    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
  };
};
