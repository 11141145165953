const app = {
  // dashboard view
  dashboard: {
    index: {
      title: "",
      campaigns: {
        title: "Recent Campaigns",
      },
      subTitle: "Here is a quick overview of your deliverability",
      inboxTrend: {
        title: "Inbox Rate Trend by Mailbox Provider",
        filterTitle: 'Inbox Rate Trend by Mailbox Provider'
      },
      inboxIpTrend: {
        title: "Inbox Rate Trend by IP Address",
        filterTitle: 'Inbox Rate Trend by IP Address'
      },
    },
  },

  // inbox placement view
  inboxPlacement: {
    index: {
      // View title
      title: "Inbox Placement",
      noResults: "No accounts found",
    },
  },

  // Account settings tab
  accountSettings: {
    // Seed list tab
    seedListTab: {
      title: "Seeds List",
      subTitle:"Send email campaigns to the seed list to test deliverability.",
      noResults: "No seeds found",
    },
   // Account settings form
    accountform:{
      title:'Account Information',
      thresholds:{
        title:'Deliverability Metric Thresholds',
        subTitle:'If deliverability falls outside of the set threshold limits, shading is applied to the data to help you discover concerns at a glance.'
      },
      successMsg:'Account updated successfully'
    },
    // Inbox monitoring tab
    inboxMonitoring:{
      title:"Mailbox Providers",
      caption:"(Optional)",
      subTitle  :`Select the mailbox providers by geographical region to appear in
      inbox placement data and drop-down lists through this account.`,
      treeFilterTitle:"Mailbox Providers",
      successMsg:"Account changes successfully saved."
    }
  },
  // Account campaigns deliverability view
  accountCampaigns: {
    index: {
      // View title
      title: "Campaign Deliverability",
      subTitle:"Campaign Details",
      noResults: "No campaigns found",
    },
  },
  // Mailbox providers deliverability view
  mailboxProviders: {
    index: {
      // View title
      title: "Mailbox Provider Deliverability",
      noResults: "No mailbox provider found",
    },
  },

  // Manage seed view
  seeds:{
    index: {
      title: "Manage Seed List",
      noResults: "No seeds found",
      subtitle: "Seed Addresses",
      description: "Here's a list of all the seed addresses on the seed list."
    }
  },
  // Email seeds deliverability view
  emailSeeds: {
    index: {
      // View title
      title: "Seed Email Deliverability",
      noResults: "No email seeds found",
    },
  },
  //  Users  view
  users:{
    index:{
      title:"Users",
      subTitle:"Here’s a list of all the users who have access to the <<account>> account.",
      noResults: "No users found",
      
    },
    accessDenied: "You don't have permission to access this link",
    delete:{
      title: "Delete <<name>>",
      description: "This user will no longer have access to the <<account>> account.",
    },
    suspend:{
      title: "Suspend User",
      description: "Are you sure you want to suspend the selected user?",
    },
    deletedMsg: "User <<name>> deleted successfully",
    suspendedMsg: "User <<name>> suspended successfully",
    createdMsg: "A user <<name>> created successfully !",
    updatedMsg: "User <<name>> updated successfully!",
     // Error messages
     errorMessages: {
      passwordMisMatch: "Temporary password and Confirm Temporary password must match.",
      passwordInvalid: "Temporary password is not valid",
      currentPasswordInvalid: "Confirm Temporary password password is not valid",
      userDoesNotExists :"User does not exists."
    },
  },

  // Coming soon view
  comingSoon: {
    title: "Coming soon!",
    subtitle: "We will notify you once the _ feature is available.",
    btnText:'BACK TO DASHBOARD'
  },
  /**
   * Login form
   */
  loginForm: {
    // Error message
    invalidUserMsg: "The email or password you entered is incorrect.",
  },

  /**
   * User profile view
   */
  userProfile: {
    title: "Profile",
    form: {
      title: "Profile Information",
      subtitle:'Login Information',
      successMsg :'Profile updated successfully!'
    },
    /**
     * Reset pasword
     */
     resetPassword: {
      title: "Change Password",
      subtitle:"To ensure your information is secure, please choose a password that is unique and difficult to guess",
      // Error messages
        errorMessages: {
        passwordMisMatch: "New password and Confirm password must match.",
        passwordInvalid: "New password is not valid",
        currentPasswordInvalid: "Current password is not valid",
      },
      // success messages
      successMessages:{
        passwordChangedSuccessfully:'Password changed successfuly.'
      }
    },
  },
  // Forgot pasword view
  forgotPassword:{
    form:{
      title:'Forgot Your Password?',
      subtitle:'No worries, just enter your registered email address',
      invalidUserMsg:'Sorry, there is no account with that email address.'
    }
  },
  // Reset password view and form
  resetPassword:{
    form:{
      title:'Reset Your Password',
    },
    errorMessages:{
      passwordInvalid:'New password is not valid',
      passwordMisMatch:'New password and Confirm password must match.',
      weakPassword:'Password is weak.',
      linkTokenExpired:'Link is expired',
      accountDisabled:'Account is disabled'
    },
    successMessages:{
      passwordChanged:'Password changed successfully',
    }
  },
  // Forgot password confirmation view 
  forgotPasswordConfirmation: {
      title: 'Password Reset Email Sent',
      text : `We’re checking our records to find the InboxAware account for`,
      subtext:`If we find a match, you’ll get an email with further instructions. 
      If you don't receive anything, 
      please check that you’ve entered the correct email address and check your spam folder.`
  },
  // Auth forms wrapper
  authFormWrapper: { 
    terms: "Terms & Conditions",
    privacy: "Privacy Policy"
  },
  // Session expired view
  sessionExpired:{
    title:'Your Session is Expired'
  },
// App Header
  appHeader: {
    accountsFilter: {
      noResults: "No accounts found",
    },
  },
  /**
   * Accept invitation view
   */
  acceptInvitation:{
    title: 'Accept Invitation'
  },
  /** Logged out view */
  loggedOut: {
    title: 'Logged Out Successfully!'
  },
  // Common messages
  common: {
      seeds: {
      loading: "Loading seeds...",
      noData: "No seeds available.",
    },
      campaigns: {
      loading: "Loading campaigns...",
      noData: "No campaigns available.",
    },
      mailboxProviders: {
      loading: "Loading mailbox providers...",
      noData: "No mailbox providers available.",
    },
      users: {
      loading: "Loading users...",
      noData: "No users available.",
    },
  },
};

export default Object.freeze(app);
