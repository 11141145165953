import VuexPersistence from "vuex-persist";
import vuexConfig from "@/config/vuex.persist.config";

/**
 * Persist config
 */
const persistConfig = {
  ...vuexConfig.vuexPersist,
};

/**
 * Vuex local persist
 */
const vuexPersist = new VuexPersistence(persistConfig);

export default vuexPersist;
