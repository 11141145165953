<template>
  <div class="form-group my-4">
    <v-btn
      text
      type="reset"
      @click="resetForm"
      class="font-weight-bold text--secondary btn-light-grey-border mr-2"
    >
      {{ resetBtnName }}
    </v-btn>
    <v-btn
      outlined
      type="submit"
      :disabled="!isFormValid"
      class="font-weight-bold"
      :class="{ 'text--secondary btn-light-grey-border': isFormValid }"
    >
      {{ submitBtnName }}
    </v-btn>
  </div>
</template>

<script>
/**
 * Filter form buttons
 * @description to be used to handle form submition and resetting
 */
export default {
  name: "FilterFormButtons",
  /**
   * -------------- Props --------------
   */
  props: {
    resetForm: { type: Function, required: true },
    isFormValid: { type: Boolean, default: false },
    resetBtnName: { type: String, default: "Reset" },
    submitBtnName: { type: String, default: "Apply" },
  },
};
</script>

