import Snackbar from "./Snackbar.vue";
import Breadcrumbs from "./Breadcrumbs.vue";
import Loader from "./Loader.vue";

/**
 * Object of all the global components to be registered globally
 */
const globalComponents = {
  Snackbar,
  Breadcrumbs,
  Loader,
};

export default globalComponents;
