import Vue from "vue";
import * as injectables from "@/injectables";
import paths from "@/constants/routes-path";
import * as dictionary from "@/dictionary";
import globalComponents from "../components/global";
import { appConfig } from "@/config";
import * as filters from "../filters";
import * as images from "@/assets/images";
import { NODE_ENVS } from "../constants/app";
import dayjs from 'dayjs'

/**
 * Throws an error if the required param or argument of a function is not passed
 * @param {String} paramName function parameter which is required
 * @returns {Error} error details of required param
 */
export const required = (paramName) => {
  throw new Error(`${paramName} parameter is required.`);
};

/**
 * Returns the name of property defined in a javascript object
 * @param {*} property javascript object property whose name is to be fetched
 * @returns {String} property name
 */
export const getPropertyName = (object, property = required()) => {
  if (!property) return;

  return;
};

/**
 * All the injectables and services to be injected in the vue globally
 * @todo import and add all the services or the modules that needs to be injected
 * and used in the vue glovbally
 */
const injectablesObj = {
  paths,
  dictionary,
  appConfig,
  images,
  ...injectables,
  dayjs
};

/**
 * Determines if NODE_ENV env is test
 * @returns {Boolean} Boolean value determining that it test env or not
 */
export const isTestEnv = () => process.env.NODE_ENV === NODE_ENVS.test;

/**
 * Iterates over all the injectables and inject them into the vue globally
 * @param {Vue Instance} vueInstance Vue instance in which injectables are to be injected
 */
export const injectInjectables = (vueInstance = null) => {
  let vue = Vue;

  /**
   * Checks the NODE_ENV to be test and then injects injectables in the vue instance of the component
   * which is passed as a param to the function. So that test cases for that component runs successfully.
   */
  if (vueInstance && isTestEnv()) {
    vue = vueInstance;
  }

  // Iterate and injects all the injectables
  for (const key in injectablesObj) {
    vue.prototype[`$${key}`] = injectablesObj[key];
  }
};

/**
 * Registers components globally
 * @param {Vue Instance} vueInstance Vue instance in which injectables are to be injected
 * @description these components can be used gloabally wihtout importing them locally in the component
 */
export const registerGlobalComponents = (vueInstance = null) => {
  let vue = Vue;

  /**
   * Checks the NODE_ENV to be test and then registers components globally in the vue instance provided
   * which is passed as a param to the function. So that test cases for that component runs successfully.
   */
  if (vueInstance && isTestEnv()) {
    vue = vueInstance;
  }

  // Iterates and regiters components globally
  for (const key in globalComponents) {
    vue.component(key, globalComponents[key]);
  }
};

/**
 * Injects filters globally in the vue globally
 * @description Iterates over all the filters and then injects them globally
 */
export const injectFilters = () => {
  // Iterates over all the filters
  for (const key in filters) {
    Vue.filter(key, filters[key]);
  }
};

/**
 * Rejects the promise with api error
 * @returns {Error} error which occured which consuming an api
 */
export const rejectPromise = (error) => Promise.reject(error);

/**
 * Returns a new array with csv fileds to be exported in the csv file
 * @param {Array} data -
 * @returns {Array} arry of csv fileds which are to be exported in csv
 */
export const getCsvFields = (data) => data.map(({ value }) => value);

export const getCsvLabels = (data) =>  data.map(({ text, value }) => ({ [text]: value }));

/**
 * Determines if the node_env is development or not
 * @returns {Boolean} Is env development or not
 */
export const isDevEnv = () => process.env.NODE_ENV === NODE_ENVS.dev;
