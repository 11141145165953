/**
 * APi endpoints
 * @constant All api endpoints defined will be constants
 */
const apiEndpoints = {
  account: "/accounts",
  seeds: "seeds",

  // Account campaigns
  accountCampaigns: "campaigns",

  // Camapign mail providors
  email: "/emails",
  trends: "trends",

  // Sending Domains
  sendingDomains: "from_domains",

  // Sending IPs
  sendingIPs: "sending_ips",

  countries: "/countries",
  states: "/states.json",

  // Users
  createUserProfile: "/profiles/create",
  users: "users",

  /**
   * Reset password
   * @description below mentioned url is url of Go microservice which is being used to set reset
   * password email to the user
   */
  resetPassword: "https://app.inboxaware.com/api/reset-password",

  // Roles
  roles: "roles",

  // Timezones
  timezones: "/time_zones",

  // User profile
  profile: "/profile",

  // Mailbox provider filter
  mailboxProviders: "mailbox_providers",
};

apiEndpoints.addUserToAccount = `${apiEndpoints.users}/create`;
apiEndpoints.deleteUser = `${apiEndpoints.users}/delete`;

apiEndpoints.createAccount = `${apiEndpoints.account}/create`;

/** Inbox rate trends apis */
apiEndpoints.ipAddressTrends = `${apiEndpoints.trends}/sending_ips`;
apiEndpoints.mailboxProvidersTrends = `${apiEndpoints.trends}/mailbox_providers`;
apiEndpoints.seedDomainTrends = `${apiEndpoints.trends}/from_domains`;

/** Account campaign seed emails */
apiEndpoints.campaignMailboxProviders = `${apiEndpoints.accountCampaigns}/{{campaign_id}}${apiEndpoints.email}`;

export default Object.freeze(apiEndpoints);
