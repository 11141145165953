import $paths from "@/constants/routes-path.js";

/**
 * Email deliverability routes
 */
export default [
  /**
   * Commenting this route because inboxPlacement view have been removed from current iteration
   * @todo Uncomment this to add the inbox placement view which lists the accounts
   */
  // {
  //   path: $paths.inboxPlacement,
  //   name: "inbox-placement",
  //   meta: {
  //     layout: "App",
  //   },
  //   component: () => import("@/views/InboxPlacement.vue"),
  // },
  {
    path: $paths.accountCampaigns,
    name: "account-campaigns",
    meta: {
      layout: "App",
      requiresAuth: true,
    },
    component: () => import("@/views/Campaigns.vue"),
  },
  {
    path: $paths.campaignMailboxProviders,
    name: "campaign-mailbox-providers",
    meta: {
      layout: "App",
      requiresAuth: true,
    },
    component: () => import("@/views/MailboxProviders.vue"),
  },
  {
    path: $paths.emailAddressSeeds,
    name: "email-address-seeds",
    meta: {
      layout: "App",
      requiresAuth: true,
    },
    component: () => import("@/views/EmailSeeds.vue"),
  },
  {
    path: $paths.reputationMonitoring,
    name: "reputation-monitoring",
    meta: {
      layout: "App",
      requiresAuth: true,
    },
    component: () => import("@/views/ComingSoon.vue"),
  },
  {
    path: $paths.blocklistMonitoring,
    name: "blocklist-monitoring",
    meta: {
      layout: "App",
      requiresAuth: true,
    },
    component: () => import("@/views/ComingSoon.vue"),
  },
  {
    path: $paths.userProfile,
    name: "user-profile",
    meta: {
      layout: "App",
      requiresAuth: true,
    },
    component: () => import("@/views/UserProfile.vue"),
  },
];
