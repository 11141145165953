<template>
  <div class="deliverability-matrics__tile mb-6">
    <strong class="caption text--secondary">{{ name }}</strong>
    <transition name="fade">
      <div class="my-1">
        <span class="body-2 text--primary">{{ value }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * Renders the content
 */
export default {
  name: "Content",
  /**
   * ---------------- Props ------------------
   */
  props: {
    name: {
      type: String,
      default: "Name",
      required: true,
    },
    value: {
      type: [String, Number],
      default: "",
      required: true,
    },
  },
};
</script>
