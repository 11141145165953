<template>
  <div id="app-layout">
    <v-app :style="themeStyle">
      <div id="inbowaware-nav-drawer" class="d-flex flex-row">
        <NavDrawer :isDrawerOpen="isDrawerOpen" @toggle-drawer="toggleDrawer" />
      </div>

      <div id="inboxaware-header">
        <AppHeader @toggle-drawer="toggleDrawer" />
      </div>

      <v-main id="main-section" class="">
        <v-container fluid class="px-5 py-4 px-md-8 py-md-6">
          <transition name="fade" mode="out-in">
            <!--------------- Renders vue routes  --------------->
            <router-view />
          </transition>
        </v-container>
      </v-main>
      <snackbar
        :snackbarValue="snackbar.value"
        :snackBarIcon="snackbar.icon"
        :snackBarType="snackbar.type"
        @close="closeSnackbar"
      >
        {{ snackbar.message }}
      </snackbar>
    </v-app>
  </div>
</template>

<script>
import NavDrawer from "@/components/NavDrawer";
import AppHeader from "@/components/AppHeader";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppLayout",
  components: {
    NavDrawer,
    AppHeader,
  },
  data: () => ({
    isDrawerOpen: false,
  }),
  computed: {
    ...mapGetters({
      snackbar: "ui/snackbar",
    }),
    /**
     * Computes app layout backgroud style on the basis of the current theme
     * being used in the application
     */
    themeStyle() {
      return { background: this.$vuetify.theme.themes.light.background };
    },
  },
  methods: {
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * Toggles left drawer navigation on the basis of the isDrawer props
     */
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },
    /**
     * Closes the snackbar and dispactches the action in the store
     */
    closeSnackbar(value) {
      const data = { value, message: "" };

      this.setSnackbar(data);
    },
  },
};
</script>
