/**
 * Listing of all the routes of the application
 * @description use camel casing for the $path new routes property
 */
const $path = {
  dashboard: "/dashboard",

  // inboxPlacement: "/inbox-placement",
  inboxMonitoring: "/inbox-monitoring",
  reputationMonitoring: "/reputation-monitoring",
  blocklistMonitoring: "/blocklist-monitoring",

  campaigns: "/campaigns",
  seeds: "/seeds",

  settings: "/settings",
  mailboxProviders: "/mailbox-providers",

  login: "/login",

  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  confirmation: "/email-sent",
  loggedOut: "/logged-out",

  userProfile: "/user-profile",
  sessionExpired: "/session-expired",

  acceptInvitation: "/accept-invitation",
};

// User account settings route
$path.accountSettings = `${$path.settings}/account`;

// Account campaigns deliverability page route
// $path.accountCampaigns = `${$path.inboxPlacement}/:id${$path.campaigns}`;
$path.accountCampaigns = `${$path.inboxMonitoring}/:id${$path.campaigns}`;

// Campaign mail providers page
$path.campaignMailboxProviders = `${$path.accountCampaigns}/:campaignId${$path.mailboxProviders}`;

// Email address seeds
$path.emailAddressSeeds = `${$path.accountCampaigns}/:campaignId${$path.mailboxProviders}/:seedDomain${$path.seeds}`;

$path.resetPasswordConfirmation = `${$path.forgotPassword}${$path.confirmation}`;
export default Object.freeze($path);
