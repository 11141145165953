<template>
  <v-app-bar
    id="inboxaware-app"
    app
    clipped-right
    clipped-left
    color="primary"
    tag="header"
    light
  >
    <v-app-bar-nav-icon
      color="white"
      @click.stop="$emit('toggle-drawer')"
      slot="default"
    >
      <v-icon color="white"> mdi-menu </v-icon>
    </v-app-bar-nav-icon>

    <v-toolbar-title
      class="align-center overflow-visible"
      :class="{ 'mr-12': !$vuetify.breakpoint.xsOnly }"
    >
      <router-link class="text-decoration-none" :to="$paths.dashboard">
        <div
          class="white--text"
          :class="{ 'mt-1': !$vuetify.breakpoint.xsOnly }"
        >
          <v-img
            :src="
              $vuetify.breakpoint.smAndDown
                ? $images.navHeaderMobileLogo
                : $images.navHeaderLogo
            "
            alt="InboxAware Logo"
            :class="$vuetify.breakpoint.smAndDown ? 'inboxaware-logo' : ''"
          />
        </div>
      </router-link>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-items>
      <account-filter />

      <v-menu offset-y :value="isMenuOpen" @input="isMenuOpen = !isMenuOpen">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            text
            id="top-right__dropdown"
            class="text-capitalize text-spaced--0 pl-4 pr-3"
          >
            <v-icon medium class="pr-1" color="white">
              mdi-account-circle
            </v-icon>
            <span
              class="d-none d-sm-flex white--text subtitle-2 secondary--font font-weight-large"
            >
              {{ userFullName | truncateText(40) }}
            </span>
            <v-icon color="white">{{
              isMenuOpen ? "mdi-menu-up" : "mdi-menu-down"
            }}</v-icon>
          </v-btn>
        </template>
        <v-list class="w-100 py-1" dense>
          <v-list-item-group color>
            <template
              v-for="{
                name,
                icon,
                elementId,
                to,
                hide,
                href,
                targetBlank,
                ...prop
              } in listItems"
            >
              <v-list-item
                v-if="!prop.hide"
                link
                :to="to"
                :href="href"
                :target="targetBlank && 'blank'"
                :key="name"
                :id="elementId"
                :class="{ 'd-none': hide }"
                class="px-5 text-black--80"
                dense
                style="min-height: 36px !important"
                :disabled="isDisabled(to)"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon v-text="icon" class="text-dark-grey" />
                </v-list-item-icon>
                <v-list-item-content
                  class="pl-3 text-capitalize body-2 text--primary"
                >
                  {{ name }}
                </v-list-item-content>
              </v-list-item>
              <!-- <v-divider :key="i" :class="{ 'd-none': hide }" /> -->
            </template>
            <v-list-item @click="logout" class="px-5">
              <v-list-item-icon class="mr-2">
                <v-icon>{{ logoutAction.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pl-3">
                <v-list-item-title
                  class="text-capitalize body-2 font-weight-regular"
                >
                  {{ logoutAction.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { AccountFilter } from "@/components/shared";

/**
 * ---------------- App Header ------------------
 */
export default {
  name: "AppHeader",
  components: {
    AccountFilter,
  },
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return {
      logoutAction: {
        name: "Logout",
        icon: "mdi-logout",
        to: "logout",
      },
      isMenuOpen: false,
    };
  },
  /**
   * ------------------- Methods ---------------------
   */
  methods: {
    ...mapActions({
      getAccounts: "account/getAccounts",
      logoutUser: "auth/logoutUser",
    }),
    /**
     * Dispatches an action in the store to logout the user
     */
    logout() {
      this.logoutUser();
    },

    /**
     * Disable the user account settings option when no account is selected.
     */
    isDisabled(to) {
      return (
        !this.selectedAccount?.account_id && to === this.$paths.accountSettings
      );
    },
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      accounts: "account/accounts",
      selectedAccount: "ui/selectedAccount",
      userProfile: "auth/currentUserProfile",
      isMaropostUser: "auth/isMaropostUser",
    }),
    /**
     * Determines that the logged in user has fullname or not
     * @type {Boolean}
     */
    hasFullName() {
      return this.userProfile?.first_name && this.userProfile?.last_name;
    },
    /**
     * Computes the fullname of the logged in user
     * @type {String}
     */
    userFullName() {
      return this.hasFullName
        ? `${this.userProfile?.first_name} ${this.userProfile?.last_name}`
        : "";
    },
    /**
     * Top header right side bar options to be shown to the user
     * @type Array
     */
    listItems() {
      return [
        {
          name: "Profile",
          icon: "mdi-account-circle",
          elementId: "profile",
          to: this.$paths.userProfile,
        },
        {
          name: this.$appConfig.apiDocs.name,
          icon: "mdi-led-variant-on",
          href: this.$appConfig.apiDocs.href,
          targetBlank: true,
          hide: true,
          elementId: "api-doccumentation",
        },
      ];
    },
  },
};
</script>
<style lang="scss">
#inboxaware-app {
  z-index: 20 !important;
}

#inboxaware-logo {
  width: 100% !important;
  height: auto !important;
}
</style>
