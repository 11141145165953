import { isDevEnv } from "@/utils";

const isEnvDev = isDevEnv();

/**
 * Api's configuration
 */
const config = {
  /**
   * Api base url
   */
  API_BASE_URL: isEnvDev
    ? `${process.env.VUE_APP_API_BASE_URL}/api/v1`
    : "/api/v1",
};

/**
 * Accounts api resource base url
 */
config.ACCOUNTS_API_BASE_URL = `${config.API_BASE_URL}/accounts`;

export default Object.freeze(config);
