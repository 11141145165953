import { apiEndpoints } from "@/constants";
import { getEndpoint } from "@/utils/api.utils";
import { $accounts } from "./apis/accounts";

const { campaignMailboxProviders } = apiEndpoints;

/**
 * Fetches campaign email seeds
 * @param {Object} params - Api params
 * @returns {Promise} APi response
 */
export const getCampaignEmailSeeds = ({ account_id, campaign_id }) => {
  let endpoint = campaignMailboxProviders.replace(
    "{{campaign_id}}",
    campaign_id
  );
  endpoint = getEndpoint(account_id, endpoint);
  return $accounts.get(endpoint);
};

/**
 * Fetches email address seed details from the api resource
 * @param {Object} params - Api params
 * @returns {Promise} APi response
 */
export const getEmail = ({ campaign_id, account_id, id, ...params }) => {
  const endpoint = getEndpoint(
    account_id,
    `${campaignMailboxProviders.replace("{{campaign_id}}", campaign_id)}/${id}`
  );
  return $accounts.get(endpoint, { params });
};

/**
 * Fetches campaign seed email source
 * @param {Object} params - Api params
 * @returns {Promise} APi response
 */
export const getEmailSource = ({ campaign_id, account_id, id }) => {
  const endpoint = `${account_id}/${apiEndpoints.accountCampaigns}/${campaign_id}${apiEndpoints.email}/${id}/source`;
  return $accounts.get(endpoint);
};
