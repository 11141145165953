import {
  getAccounts,
  getSeeds,
  getAccountCampaigns,
  mailboxProviderTrends,
  getCampaignMailboxProviders,
  getSendingDomains,
  getSendingIPs,
  ipAddressTrends,
  getMailboxProviders,
  getCampaignEmailSeeds,
  getAccountMailboxProviders,
} from "@/services";
import { rejectPromise } from "@/utils";
import { getSeedCount, isEmpty } from "@/utils/common.utils";
import { percent, duration, formatDateToAmPm, capitalize } from "@/filters";
import { RESPONSE_CODES } from "@/constants/app";
import sortBy from "lodash/sortBy";
import { restrictUser, sortIpAddress } from "@/utils/shared.utils";

/**
 * State of the module
 */
const state = {
  accounts: [],
  filterAccounts: [],
  accountCampaigns: [],
  seeds: [],
  campaignSeeds: [],
  campaignMailboxProviders: [],
  mailboxProviderTrends: {},
  sendingDomains: [],
  sendingIPs: [],
  mailboxProviders: [],
  mailboxProviderIpTrends: {},
  areAccountsLoading: false,
  hasAccountAccess: false,
};

/**
 * Mutations constants
 */
const SET_ACCOUNTS = "SET_ACCOUNTS";
const SET_FILTER_ACCOUNTS = "SET_FILTER_ACCOUNTS";
const SET_SEEDS = "SET_SEEDS";
const SET_ACCOUNT_CAMPAIGNS = "SET_ACCOUNT_CAMPAIGNS";
const SET_CAMPAIGN_MAILBOX_PROVIDORS = "SET_CAMPAIGN_MAILBOX_PROVIDORS";
const SET_MAILBOX_PROVIDER_TRENDS = "SET_MAILBOX_PROVIDER_TRENDS";
const SET_SENDING_DOMAINS = "SET_SENDING_DOMAINS";
const SET_SENDING_IP = "SET_SENDING_IP";
const SET_MAILBOX_PROVIDERS = "SET_MAILBOX_PROVIDERS";
const SET_MAILBOX_PROVIDER_IP_TRENDS = "SET_MAILBOX_PROVIDER_IP_TRENDS";
const SET_ACCOUNTS_LOADING = "SET_ACCOUNTS_LOADING";
const SET_USER_HAS_ACCOUNT_ACCESS = "SET_USER_HAS_ACCOUNT_ACCESS";
const SET_CAMPAIGN_SEEDS = "SET_CAMPAIGN_SEEDS";

/**
 *  Mutations
 */
const mutations = {
  /**
   * SET_COUNTRIES
   * @description list of countries
   */
  [SET_ACCOUNTS](state, data) {
    state.accounts = data;
  },

  /**
   * SET_FILTER_ACCOUNTS
   * @description list of countries
   */
  [SET_FILTER_ACCOUNTS](state, data) {
    state.filterAccounts = data;
  },

  /**
   * SET_SEEDS
   * @param {Array} data - List of all the seeds of an account
   * @description Adds list of the seeds of an account in the state
   */
  [SET_SEEDS](state, data) {
    return (state.seeds = data);
  },

  /**
   * SET_ACCOUNT_CAMPAIGNS
   * @param {Array} data - List of account campaigns
   * @description Adds list of accoutn campaigns in the state
   */
  [SET_ACCOUNT_CAMPAIGNS](state, data) {
    state.accountCampaigns = data;
  },

  [SET_CAMPAIGN_MAILBOX_PROVIDORS](state, data) {
    state.campaignMailboxProviders = data;
  },
  [SET_MAILBOX_PROVIDER_TRENDS](state, data) {
    state.mailboxProviderTrends = data;
  },
  [SET_SENDING_DOMAINS](state, data) {
    state.sendingDomains = data;
  },
  [SET_SENDING_IP](state, data) {
    state.sendingIPs = data;
  },
  [SET_MAILBOX_PROVIDERS](state, data) {
    state.mailboxProviders = data;
  },
  [SET_MAILBOX_PROVIDER_IP_TRENDS](state, data) {
    state.mailboxProviderIpTrends = data;
  },
  [SET_ACCOUNTS_LOADING](state, loading) {
    state.areAccountsLoading = loading;
  },
  [SET_USER_HAS_ACCOUNT_ACCESS](state, hasAccess) {
    state.hasAccountAccess = hasAccess;
  },
  [SET_CAMPAIGN_SEEDS](state, data) {
    state.campaignSeeds = data;
  },
};

/**
 *  Actions of the account module
 */
const actions = {
  /**
   * Fetched list of all the accounts
   * @param {Object} params Api params
   * @description Commits a mutation to mutate the accounts in the state
   */
  async getAccounts({ commit }, params = {}) {
    try {
      commit(SET_ACCOUNTS_LOADING, true);
      let { data } = await getAccounts(params);

      data = data ?? [];

      commit(SET_USER_HAS_ACCOUNT_ACCESS, !isEmpty(data));
      commit(SET_ACCOUNTS, data);
      commit(SET_ACCOUNTS_LOADING, false);
    } catch (error) {
      // Throws an error if user does not have access to any accounts while user is attempting to log into the application
      if (error?.response?.status === RESPONSE_CODES.unAuthorised) {
        commit(SET_USER_HAS_ACCOUNT_ACCESS, false);
        restrictUser(commit);
      } else return Promise.reject(error);
    }
  },

  /**
   * Fetched list of filter accounts
   * @param {Object} params Api params
   * @description Commits a mutation to mutate the accounts in the state
   */
  async getFilterAccounts({ commit }, params = {}) {
    try {
      let { data } = await getAccounts(params);

      data = data ?? [];
      commit(SET_FILTER_ACCOUNTS, data);
    } catch (error) {
      rejectPromise(error);
    }
  },

  /**
   * * Fetched list of all the account seeds
   * @description Commits a mutation to mutate the seeds list of an account in the state
   */
  async getSeeds({ commit }, params = {}) {
    try {
      let { data } = await getSeeds(params);

      data = data ?? [];
      commit(SET_SEEDS, data);
    } catch (error) {
      rejectPromise(error);
    }
  },

  /**
   * Fetched list of all the campaigns of an account
   * @param {Object} params Api params
   * @description Commits a mutation to mutate the account campaigns in the store state
   */
  async getAccountCampaigns({ commit }, params = {}) {
    try {
      let { data } = await getAccountCampaigns(params);

      if (!data) data = [];
      commit(SET_ACCOUNT_CAMPAIGNS, data);
    } catch (error) {
      rejectPromise(error);
    }
  },

  /**
   * Fetched list of all the mailbox providers of an campaign
   * @param {Object} params Api params
   * @description Commits a mutation to mutate the mailbox providers in the store state
   */
  async getCampaignMailboxProviders({ commit }, params = {}) {
    try {
      let { data } = await getCampaignMailboxProviders(params);

      if (!data) data = [];
      commit(SET_CAMPAIGN_MAILBOX_PROVIDORS, data);
    } catch (error) {
      rejectPromise(error);
    }
  },

  async getMailboxProviderTrends({ commit }, params = {}) {
    try {
      let { data } = await mailboxProviderTrends(params);
      data = data ?? [];

      commit(SET_MAILBOX_PROVIDER_TRENDS, data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * Fetching list of all sending domains
   * @param {Object} params Api params
   * @description Commits a mutation to mutate the sending domains in the store state
   */
  async getSendingDomains({ commit }, params = {}) {
    try {
      if (!params?.per_page) params.per_page = 50;
      let { data } = await getSendingDomains(params);

      data = data ?? [];
      commit(SET_SENDING_DOMAINS, sortBy(data, ["domain_name"]));
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * Fetching list of all sending ips
   * @param {Object} params Api params
   * @description Commits a mutation to mutate the sending ips in the store state
   */
  async getSendingIPs({ commit }, params = {}) {
    try {
      let { data } = await getSendingIPs(params);
      // Remove objects whose ip_adreess value is empty
      data = data ? data.filter(({ ip_address }) => ip_address !== "") : [];

      commit(SET_SENDING_IP, data.sort(sortIpAddress));
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * Fetches the default mailbox providers
   * @description Get mailbox provider for filter
   * @param {Object} params Query params to be used to fetch the mailbox providers
   */
  async getMailboxProviders({ commit }, params = {}) {
    try {
      // Fetches mailbox providers associated with the account
      let response = await getAccountMailboxProviders(params.account_id);

      // Fetches all available mailbox providers (Default) if no mailbox providers are associated with an account
      if (isEmpty(response?.data)) response = await getMailboxProviders(params);
      let data = response?.data ?? [];
      commit(SET_MAILBOX_PROVIDERS, data);
    } catch (error) {
      rejectPromise(error);
    }
  },
  /*
   * Fetching list of all sending domains
   * @param {Object} params Api params
   * @description Commits a mutation to mutate the sending domains in the store state
   */
  async getMailboxProviderIpTrends({ commit }, params = {}) {
    try {
      let { data } = await ipAddressTrends(params);

      if (data) {
        commit(SET_MAILBOX_PROVIDER_IP_TRENDS, data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * Fetches campaign email seeds
   */
  async getCampaignSeeds({ commit }, params = {}) {
    try {
      let { data } = await getCampaignEmailSeeds(params);
      data = data ?? [];

      commit(SET_CAMPAIGN_SEEDS, data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const getters = {
  /**
   * Returns the list of all the accounts in the state
   * @returns {Array} List of accounts
   */
  accounts({ accounts }) {
    return accounts;
  },

  /**
   * Returns the list of all the accounts that match filter in the state
   * @returns {Array} List of accounts
   */
  filterAccounts({ filterAccounts }) {
    return filterAccounts;
  },

  /**
   * Returns the list of all the seeds of an account
   * @returns {Array} List of all the seeds of an account
   */
  seeds({ seeds }) {
    return seeds;
  },

  accountCampaigns({ accountCampaigns }) {
    return accountCampaigns;
  },

  campaignMailboxProviders({ campaignMailboxProviders }) {
    return campaignMailboxProviders;
  },

  mailboxProviderTrends({ mailboxProviderTrends }) {
    return mailboxProviderTrends;
  },

  sendingDomains({ sendingDomains }) {
    return sendingDomains
      ? sendingDomains?.map(({ domain_name, ...domain }) => ({
          ...domain,
          from_domain: domain_name,
        }))
      : [];
  },

  sendingIPs({ sendingIPs }) {
    return sendingIPs
      ? sendingIPs?.map(({ ip_address, ...ipAddress }) => ({
          ...ipAddress,
          sending_ip: ip_address,
        }))
      : [];
  },

  filteredCampaigns: ({ accountCampaigns }) => (campaigns = null) => {
    accountCampaigns = campaigns ?? accountCampaigns;
    return accountCampaigns.map((campaign) => {
      let seeds_count = getSeedCount(campaign);
      return {
        ...campaign,
        inbox: percent(campaign.inbox_count, seeds_count),
        spam: percent(campaign.spam_count, seeds_count),
        missing: percent(campaign.missing_count, seeds_count),
        duration: duration(campaign.first_seen, campaign.last_seen),
        durationMillis:
          new Date(campaign.last_seen).getTime() -
          new Date(campaign.first_seen).getTime(),
        first_seen: formatDateToAmPm(campaign.first_seen),
        first_seen_date: campaign.first_seen,
      };
    });
  },
  filteredSeeds: ({ campaignSeeds }) => (seedDomain) => {
    if (!campaignSeeds || !seedDomain) return [];

    return campaignSeeds
      .filter(({ seed_domain }) => seedDomain === seed_domain)
      .map(({ received_at, category, mailbox_type, ...seed }) => ({
        ...seed,
        mailbox_type: capitalize(mailbox_type?.toLowerCase()),
        category: capitalize(category?.toLowerCase()),
        received_at: formatDateToAmPm(received_at),
      }));
  },
  /**
   * @todo Percentage calculation for the inbox count, spam count are pending
   */
  filteredMailboxProviders({ campaignMailboxProviders }) {
    if (!campaignMailboxProviders) return [];

    return campaignMailboxProviders;
  },
  mailboxProviders({ mailboxProviders }) {
    return mailboxProviders;
  },
  mailboxProviderIpTrends({ mailboxProviderIpTrends }) {
    return mailboxProviderIpTrends;
  },
  areAccountsLoading({ areAccountsLoading }) {
    return areAccountsLoading;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
