<template>
  <v-card>
    <v-chip
      v-if="icon"
      :color="color"
      left
      label
      class="py-5 px-2 mt-n1 ml-n1 tile"
    >
      <v-icon color="white" :class="iconClass">{{ icon }}</v-icon>
    </v-chip>
    <v-card-text class="pt-2 pb-3">
      <p class="card__content text-center">
        <span class="subtitle-2 text--primary font--primary">
          {{ text }}
        </span>
        <br />
        <template>
          <p class="my-2 d-flex justify-center align-baseline flex-wrap">
            <span
              v-if="hasPercent"
              class="text-h5 text-dark-grey font-weight-large text--primary secondary--font"
            >
              {{ percent }}
            </span>
            <span v-if="value" class="body-2 pl-2 text--secondary">
              ({{ value }})
            </span>
          </p>
        </template>
        <slot></slot>
      </p>
    </v-card-text>
    <loader :loading="isLoading" :size="$appConfig.loader.small" />
  </v-card>
</template>

<script>
export default {
  name: "Tile",
  props: {
    text: {
      type: String,
      required: true,
      default: "",
    },
    /**
     * Value to be shown
     */
    value: {
      type: [String, Number],
      required: true,
      default: "",
    },
    /**
     * Percent to show
     */
    percent: {
      type: [String, Number],
      required: true,
      default: "",
    },
    /**
     * Is card data loading or not
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
    iconClass: {
      type: String,
      default: "",
    },
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    /**
     * hasPercent
     * Determines if the tile component has percentage
     * @type {Boolean}
     */
    hasPercent() {
      return this.percent >= 0 || this.percent?.length >= 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.tile {
  z-index: 2 !important;
}
</style>
